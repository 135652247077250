import React from 'react';
import TopStripComponent from './TopStripComponent/index';
import Navbar from './Navbar/index';

/**
 * NavigationV2 renders the navigation bar and top strip component based on props.
 *
 * @param {boolean} hideTopStrip - Indicates whether to hide the top strip.
 * @param {boolean} hideCTA - Indicates whether to hide the CTA button.
 * @param {boolean} dark - Indicates whether to apply dark theme.
 * @param {object} program - The program data to be passed to the components.
 * @param {boolean} sale - Indicates whether there is an ongoing sale.
 * @param {object} CTAProgram - The program data for the CTA button.
 * @returns {JSX.Element} - Returns the JSX for the navigation bar and top strip.
 */

const NavigationV2 = ({
  hideTopStrip,
  hideCTA,
  dark,
  program,
  sale,
  CTAProgram,
}) => {
  return (
    <div className="fixed z-[1000] w-screen">
      {/* Render TopStripComponent only if hideTopStrip is false */}
      {!hideTopStrip && <TopStripComponent sale={sale} program={program} />}

      {/* Render Navbar component */}
      <Navbar hideCTA={hideCTA} dark={dark} CTAProgram={CTAProgram} />
    </div>
  );
};

export default NavigationV2;
