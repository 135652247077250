import React, { useContext, useState } from 'react';
import FormInput from '../FormComponents/FormInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import LeadGenerationService from '../../../../utils/lead-generation';
import { FormDispatchContext } from '../../../context/FormContextProvider';
import { GlobalDispatchContext } from '../../../context/GlobalContextProvider';
import FormSubmitButton from '../FormComponents/FormSubmitButton';
import toast from 'react-hot-toast';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'; //root-file
import FormPhoneInput from '../FormComponents/FormPhoneInput';
import FormCheckbox from '../FormComponents/FormCheckbox';
import * as Sentry from '@sentry/gatsby';
import { GTM } from '../../../analytics/gtm';
import { gtmEvents } from '../../../analytics/gtmEventsConstant';
import { faBuilding, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import {
  LEAD_GEN_UTM_CAMPAIGN_ONE,
  LEAD_GEN_DEVICE_MOBILE,
  LEAD_GEN_DEVICE_DESKTOP,
  LEAD_GEN_LOCATION_FALLBACK,
  LEAD_GEN_PATHNAME_FALLBACK,
  leadStage,
  generateDropDownValues,
  profiles,
  getGraduationYearList,
  whitelistedLeadSources,
  ERROR_LEAD_SOURCE,
  LEAD_GEN_DATA_SOURCE,
  extendLeadObjectWithUserLocationData,
  LEAD_GEN_PROGRAM_ID_QA,
  LEAD_GEN_QA_AUTOMATION_PATH,
  LEAD_GEN_AUTOMATION_EDGE_PATH,
  jobDomains,
  jobRoleTechs,
  jobRoleNonTechs,
  companies,
  LSQ_PROFILE,
} from '../../../constants/LeadGenerationConstants/index';
import { useLocation } from '@reach/router';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import useResizer from '@components/extra/useResizer';
import {
  LEAD_GEN_UTM_SOURCE_QA,
  LEAD_GEN_UTM_SOURCE_DEV,
} from '../../../constants/LeadGenerationConstants/index';
import { Box } from '@mui/material';
import FormDropDown from '../FormComponents/FormDropDown';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import FormAutoComplete from '../FormComponents/FormAutoComplete';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';

// Regex for Indian phone numbers; TODO: create a seperate regex array for all-country validation
const indianRegex = new RegExp('^[6-9][0-9]{9}$');

// Yup schema to validate page-1 form fields
const pageOneSchema = yup.object({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is a required field'),
  phone: yup
    .string()
    .required('Phone is a required field')
    .test('Check Indian Number', function () {
      let contact = this.parent['phone'];

      if (contact && contact.startsWith('91')) {
        let contactWithoutCountryCode = contact.substring(2, contact.length);
        return indianRegex.test(contactWithoutCountryCode);
      }

      return contact.length >= 8; // temporary logic to validate at-least 8 digit phone number (8 digits include country code)
    }),
});

const pageOneExtendedSchema = pageOneSchema.shape({
  whatsAppUpdates: yup.boolean().required(),
});

const pageOneFullStackPpcVlSchema = pageOneSchema.shape({
  whatsAppUpdates: yup.boolean().required(),
  currentlyWorking: yup.string().required('Working Status is a required field'),
  jobDomain: yup.string().when('currentlyWorking', {
    is: 'Yes',
    then: yup.string().required('Job Domain is a required field'),
    otherwise: yup.string(),
  }),
  jobRole: yup.string().when('currentlyWorking', {
    is: 'Yes',
    then: yup.string().required('Job Role is a required field'),
    otherwise: yup.string(),
  }),
  company: yup.string().when('currentlyWorking', {
    is: 'Yes',
    then: yup.string().required('Company is a required field'),
    otherwise: yup.string(),
  }),
});

const pageOneExtraFieldsExtendedSchema = pageOneSchema.shape({
  graduationYear: yup
    .number()
    .typeError('Must be a number')
    .positive('Must be a positive number')
    .integer('Must be an integer')
    .required('Graduation Year is a required field'),
  currentlyWorking: yup.string().required('Profile is a required field'),
});

export default function PageOne({
  setPage,
  buttonLocation,
  slackChannel,
  isOnePageForm,
  closeDialog,
  onSubmitPageOne,
  pageOneButtonText,
  isOpenForm,
  applicationPage = '',
  pageOneGradYear,
  pageOneWorkingProfessional,
  programType,
  isFullStackPpcVl,
  customUtmParams,
}) {
  // State variables
  const dispatch = useContext(FormDispatchContext);
  const globalDispatch = useContext(GlobalDispatchContext);

  const [loading, setLoading] = useState(false);
  //state to track if a form field is focused:
  const [isFormTouched, setIsFormTouched] = useState(false);
  const isMobile = useResizer();

  // Global state
  const globalState = useContext(GlobalStateContext);
  const storedParams = globalState.utm;
  const userLocation = globalState.userLocation;
  const searchParam = new URLSearchParams(storedParams);

  // Path variables
  const location = useLocation();
  const pathName = location?.pathname;

  // Check if the current lead if of type QA
  const isMQALead =
    pathName === LEAD_GEN_QA_AUTOMATION_PATH ||
    pathName === LEAD_GEN_AUTOMATION_EDGE_PATH ||
    programType === LEAD_GEN_PROGRAM_ID_QA;
  const isSde2Lead =
    pathName === '/software-development-fellowship-program-plus/';

  const { isDataSciencePage } = useDataScienceProgram();

  // UTM variables
  const utmSource = searchParam.get('utm_source') || customUtmParams?.utmSource;
  const utmMedium = searchParam.get('utm_medium') || customUtmParams?.utmMedium;
  const utmPublisher = searchParam.get('utm_publisher');
  const utmNetwork = searchParam.get('utm_network');
  const utmCampaign =
    searchParam.get('utm_campaign') || customUtmParams?.utmCampaign;
  const utmTerm = searchParam.get('utm_term');
  const utmContent = searchParam.get('utm_content');
  const gclID = searchParam.get('gclid');
  const lcID = searchParam.get('li_fat_id');
  const fbclID = searchParam.get('fbclid');

  // Form Schema and watch variables
  const hookForm = useForm({
    defaultValues: {
      email: isOnePageForm ? globalState.email : '',
      phone: isOnePageForm ? globalState.phone : '',
      ...(!isOnePageForm && { whatsAppUpdates: true }),
      ...(isOnePageForm &&
        pageOneGradYear &&
        pageOneWorkingProfessional && {
          graduationYear: 2022,
          currentlyWorking: '',
        }),
    },
    resolver:
      isOnePageForm && pageOneGradYear && pageOneWorkingProfessional
        ? yupResolver(pageOneExtraFieldsExtendedSchema)
        : isOnePageForm
        ? yupResolver(pageOneSchema)
        : isFullStackPpcVl
        ? yupResolver(pageOneFullStackPpcVlSchema)
        : yupResolver(pageOneExtendedSchema),
    mode: 'all',
  });

  const currentlyWorkingWatcher = hookForm.watch('currentlyWorking');
  const domainWatcher = hookForm.watch('jobDomain');
  const jobRoleWatcher = hookForm.watch('jobRole');
  const companyWatcher = hookForm.watch('company');
  const isCurrentlyWorking = currentlyWorkingWatcher === 'Yes';
  const isDomainTech = domainWatcher === 'Tech';

  const fullStackPpcValueLeadFlow = (data) => {
    // Determine valid job role and company values
    const jobRole = [...jobRoleTechs, ...jobRoleNonTechs].includes(
      jobRoleWatcher,
    )
      ? jobRoleWatcher
      : 'Other';
    const company = companies.includes(companyWatcher)
      ? companyWatcher
      : 'Other';

    // Assign currently working status
    data.currentlyWorking = currentlyWorkingWatcher;

    if (isCurrentlyWorking) {
      // Assign job-related data
      data.jobDomain = domainWatcher;
      data.jobRole = jobRole;
      if (jobRole === 'Other') data.jobRoleOther = jobRoleWatcher;

      // Assign company-related data
      data.currentCompany = company;
      if (company === 'Other') data.currentCompanyOther = companyWatcher;
      data.profile = LSQ_PROFILE.WORKING_PROFESSIONAL;
    } else {
      data.profile = LSQ_PROFILE.NON_WORKING_PROFESSIONAL;
    }
  };

  // Creates a document of lead-data on firebase
  const onSubmitLeadGenFormPageOne = async (data) => {
    try {
      setLoading(true);

      data.program = isSde2Lead
        ? 'Software Development Plus'
        : isMQALead
        ? 'QA Automation'
        : isDataSciencePage
        ? 'Data Analytics'
        : 'Software Development';

      // concatenate + to phone-number
      data.phone = data.phone.startsWith('+')
        ? data.phone
        : '+'.concat(data.phone);

      // make email lowercase
      data.email = data.email.toLowerCase();

      //Lead details flow for Full Stack PPC Value Lead Partnership:
      isFullStackPpcVl && fullStackPpcValueLeadFlow(data);

      // add data source key
      data.dataSource = LEAD_GEN_DATA_SOURCE;

      // add UTM data
      if (utmSource) {
        if (whitelistedLeadSources.includes(utmSource))
          data.utmSource = utmSource;
        else data.utmSource = ERROR_LEAD_SOURCE;
      }
      if (utmMedium) data.utmMedium = utmMedium;
      if (utmPublisher) data.utmPublisher = utmPublisher;
      if (utmCampaign) data.utmCampaign = utmCampaign;
      if (utmContent) data.utmContent = utmContent;
      if (utmNetwork) data.utmNetwork = utmNetwork;
      if (utmTerm) data.utmTerm = utmTerm;
      if (gclID) data.gclID = gclID;
      if (lcID) data.lcID = lcID;
      if (fbclID) data.fbclID = fbclID;

      // Slack channel
      if (slackChannel) data.slackChannel = slackChannel;

      // Add URL field
      data.formURL = pathName || LEAD_GEN_PATHNAME_FALLBACK;

      // Add location field
      data.location = buttonLocation || LEAD_GEN_LOCATION_FALLBACK;

      // Add current page number of form for tracking
      data.formStep = LEAD_GEN_UTM_CAMPAIGN_ONE;

      // Add type of device used
      data.deviceType = isMobile
        ? LEAD_GEN_DEVICE_MOBILE
        : LEAD_GEN_DEVICE_DESKTOP;

      // Add user location data
      extendLeadObjectWithUserLocationData(data, userLocation);

      // Add application stage type
      data.applicationStage = leadStage.HALF_FILL;

      const lead = await LeadGenerationService.generateLead(data);
      const leadID = lead.data.leadID;

      // Save Document-ID for further update-document request to firebase
      dispatch({
        type: 'SET_DOCUMENT_ID',
        payload: leadID,
      });

      dispatch({
        type: 'SET_EMAIL',
        payload: data.email,
      });

      if (isFullStackPpcVl) {
        dispatch({
          type: 'SET_WORKING_STATUS',
          payload: currentlyWorkingWatcher,
        });
      }
      GTM.setEmail(data.email);

      // Set email, phone & docID details in global context
      globalDispatch({
        type: 'SET_EMAIL',
        payload: data.email,
      });

      globalDispatch({
        type: 'SET_PHONE',
        payload: data.phone,
      });
      globalDispatch({
        type: 'SET_GLOBAL_LEAD_DOCUMENT_ID',
        payload: leadID,
      });

      // Send a GTM event for half filled form
      GTM.login({
        userId: data.email,
        email: data.email,
        phone: data.phone,
      });
      GTM.track(gtmEvents.LEAD_GEN_HALF_FORM_FILL, {
        url: location.href,
        email: data.email,
        location:
          pathName === LEAD_GEN_QA_AUTOMATION_PATH ||
          pathName === LEAD_GEN_AUTOMATION_EDGE_PATH
            ? LEAD_GEN_UTM_SOURCE_QA
            : LEAD_GEN_UTM_SOURCE_DEV,
        buttonLocation: buttonLocation,
        applicationPage,
        // ? 'CRIO_REBOOT' : '',
      });

      //ga event for successful L1 form submission
      GTM.track(gtmEvents.L1_SUCCESSFULL_SUBMISSION, {
        url: location.href,
        email: data.email,
        location:
          pathName === LEAD_GEN_QA_AUTOMATION_PATH ||
          pathName === LEAD_GEN_AUTOMATION_EDGE_PATH
            ? LEAD_GEN_UTM_SOURCE_QA
            : LEAD_GEN_UTM_SOURCE_DEV,
        buttonLocation: buttonLocation,
        applicationPage,
      });
      GTM.leadSquaredEvent(ELeadSquaredActivityCode.NEXT_CLICKED, data.email);

      //GTM Event for Email Capture
      if (!globalState.isEmailEventSubmitted) {
        GTM.emailSubmission(data.email, data.phone);

        globalDispatch({
          type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
          payload: true,
        });
      }

      setLoading(false);
      setPage(2);
    } catch (error) {
      setLoading(false);

      toast.error('Could not process request, please try again later.', {
        duration: 6000,
        style: { fontSize: '14px' },
        id: 'lead-gen-form-page-1',
      });

      // Log error to webengage
      GTM.track(gtmEvents.LEAD_GEN_FORM_ERRORS, {
        email: data?.email,
        error: error.message,
      });

      //ga events for unsuccessfull L1 form submission
      GTM.track(gtmEvents.L1_UNSUCCESSFULL_SUBMISSION, {
        email: data?.email,
        error: error.message,
      });

      // Log error to sentry
      Sentry.withScope(function (scope) {
        scope.setUser({ email: data.email });
        scope.setTag('lead_gen_form', 'critical');
        scope.setLevel('critical');
        Sentry.captureException(error);
      });
    }
  };

  const onSubmitLeadPageOne = async (data) => {
    try {
      setLoading(true);

      data.program = isSde2Lead
        ? 'Software Development Plus'
        : isMQALead
        ? 'QA Automation'
        : isDataSciencePage
        ? 'Data Analytics'
        : 'Software Development';

      data.phone = data.phone.startsWith('+')
        ? data.phone
        : '+'.concat(data.phone);
      data.email = data.email.toLowerCase();
      data.formURL = pathName || LEAD_GEN_PATHNAME_FALLBACK;
      data.location = buttonLocation || LEAD_GEN_LOCATION_FALLBACK;
      data.deviceType = isMobile
        ? LEAD_GEN_DEVICE_MOBILE
        : LEAD_GEN_DEVICE_DESKTOP;
      data.applicationStage = leadStage.OTHER_LEAD;
      data.dataSource = LEAD_GEN_DATA_SOURCE;

      if (utmSource) {
        if (whitelistedLeadSources.includes(utmSource))
          data.utmSource = utmSource;
        else data.utmSource = ERROR_LEAD_SOURCE;
      }
      if (utmMedium) data.utmMedium = utmMedium;
      if (utmPublisher) data.utmPublisher = utmPublisher;
      if (utmCampaign) data.utmCampaign = utmCampaign;
      if (utmContent) data.utmContent = utmContent;
      if (utmNetwork) data.utmNetwork = utmNetwork;
      if (utmTerm) data.utmTerm = utmTerm;
      if (gclID) data.gclID = gclID;
      if (lcID) data.lcID = lcID;
      if (fbclID) data.fbclID = fbclID;

      // Add user location data
      extendLeadObjectWithUserLocationData(data, userLocation);

      const lead = await LeadGenerationService.generateLead(data);
      const leadID = lead.data.leadID;

      // Set email, phone & docID details in global context
      globalDispatch({
        type: 'SET_EMAIL',
        payload: data.email,
      });
      globalDispatch({
        type: 'SET_PHONE',
        payload: data.phone,
      });
      globalDispatch({
        type: 'SET_GLOBAL_LEAD_DOCUMENT_ID',
        payload: leadID,
      });

      onSubmitPageOne(data.email, data.phone);

      //ga events for successfull L1 form submission
      GTM.track(gtmEvents.L1_SUCCESSFULL_SUBMISSION, {
        url: location.href,
        email: data.email,
        location:
          pathName === LEAD_GEN_QA_AUTOMATION_PATH ||
          pathName === LEAD_GEN_AUTOMATION_EDGE_PATH
            ? LEAD_GEN_UTM_SOURCE_QA
            : LEAD_GEN_UTM_SOURCE_DEV,
        buttonLocation: buttonLocation,
        applicationPage,
      });

      setLoading(false);
      if (closeDialog) closeDialog();
    } catch (error) {
      console.log(error);
      setLoading(false);

      //ga events for unsuccessfull L1 form submission
      GTM.track(gtmEvents.L1_UNSUCCESSFULL_SUBMISSION, {
        email: data?.email,
        error: error?.message,
      });

      toast.error('Could not process request, please try again later.', {
        duration: 6000,
        style: { fontSize: '14px' },
        id: 'lead-gen-form-page-1',
      });
    }
  };

  // Wrapper function for click handler based on form type
  const submitPageOne = async (data) => {
    //GA EVENT for submit button of L1 form:
    if (onSubmitPageOne) await onSubmitLeadPageOne(data);
    else await onSubmitLeadGenFormPageOne(data);
  };

  //handler function to detect when a field is focused and send the event to GA:
  const handleFocus = () => {
    if (!isFormTouched) {
      //only register one attempt for form filling:
      GTM.track(gtmEvents.L1_FORM_FILL_ATTEMPT, {
        url: location.href,
        location:
          pathName === LEAD_GEN_QA_AUTOMATION_PATH ||
          pathName === LEAD_GEN_AUTOMATION_EDGE_PATH
            ? LEAD_GEN_UTM_SOURCE_QA
            : LEAD_GEN_UTM_SOURCE_DEV,
        buttonLocation: buttonLocation,
      });
      setIsFormTouched(true);
    }
  };

  return (
    <>
      <FormProvider {...hookForm}>
        <form
          autoComplete="on"
          className={`mt-2 flex w-full flex-col items-stretch ${
            isOnePageForm ? 'gap-5' : 'gap-3.5'
          }  overflow-y-auto scrollbar-hide`}
          onSubmit={hookForm.handleSubmit(submitPageOne)}
        >
          <FormInput
            icon={<FontAwesomeIcon icon={faEnvelope} />}
            label="Email"
            name="email"
            // autocomplete="email"
            error={hookForm.formState.errors?.email?.message}
            isOpenForm={isOpenForm}
            handleFocus={handleFocus}
          />
          <FormPhoneInput
            error={hookForm.formState.errors?.phone?.message}
            validation={
              hookForm.formState.dirtyFields?.phone &&
              !hookForm.formState.errors?.phone
            }
            isOpenForm={isOpenForm}
            handleFocus={handleFocus}
          />
          {isOnePageForm && pageOneGradYear && pageOneWorkingProfessional && (
            <Box
              className={`grid ${
                isMobile ? 'gap-3.5' : 'grid-cols-[45%_auto] gap-x-3'
              }`}
            >
              <FormDropDown
                icon={<FontAwesomeIcon icon="fa-solid fa-graduation-cap" />}
                name="graduationYear"
                label="Graduation Year"
                menu_items={getGraduationYearList()}
                isOpenForm={isOpenForm}
              />
              <FormDropDown
                icon={<FontAwesomeIcon icon="fa-solid fa-user" />}
                label="Are you working?"
                menu_items={generateDropDownValues(profiles)}
                name="currentlyWorking"
                isOpenForm={isOpenForm}
              />
            </Box>
          )}
          {isFullStackPpcVl && (
            <>
              <FormDropDown
                icon={<FontAwesomeIcon icon="fa-solid fa-user" />}
                label="Are you working?"
                menu_items={generateDropDownValues(profiles)}
                name="currentlyWorking"
                isOpenForm={isOpenForm}
              />
              {isCurrentlyWorking && (
                <FormDropDown
                  icon={<FontAwesomeIcon icon={faBriefcase} />}
                  menu_items={generateDropDownValues(jobDomains)}
                  name="jobDomain"
                  label="Job Domain"
                  isOpenForm={isOpenForm}
                />
              )}
              {isCurrentlyWorking && domainWatcher && (
                <FormAutoComplete
                  icon={<FontAwesomeIcon icon={faBriefcase} />}
                  menu_items={
                    isDomainTech
                      ? generateDropDownValues(jobRoleTechs)
                      : generateDropDownValues(jobRoleNonTechs)
                  }
                  name="jobRole"
                  label="Job Role"
                  isOpenForm={isOpenForm}
                />
              )}
              {isCurrentlyWorking && (
                <FormAutoComplete
                  icon={<FontAwesomeIcon icon={faBuilding} />}
                  label="Company"
                  name="company"
                  menu_items={generateDropDownValues(companies)}
                  isOpenForm={isOpenForm}
                />
              )}
            </>
          )}
          {!isOnePageForm && (
            <FormCheckbox
              text="I agree to receive SMS & Whatsapp communications on this number."
              name="whatsAppUpdates"
              isOpenForm={isOpenForm}
            />
          )}
          <FormSubmitButton
            text={pageOneButtonText ? pageOneButtonText : 'Next'}
            loading={loading}
            className={isOpenForm ? `flex w-full` : 'w-fit'}
          />
        </form>
      </FormProvider>
    </>
  );
}
