import { EPathname } from '@src/constants/routes/index';

const getDemoSlotByProgram = (program) => {
  switch (program) {
    case 'Software Development':
      return 'https://rebrand.ly/crio-sd-on-demand';
    case 'QA Automation':
      return 'https://rebrand.ly/crio-qa-on-demand';
    case 'Data Analytics':
      return 'https://rebrand.ly/crio-data-analytics-on-demand';
    case 'Accelerate':
      return 'https://rebrand.ly/crio-accelerate-on-demand';
    default:
      return 'https://rebrand.ly/crio-sd-on-demand';
  }
};

export const demoSlotRedirectUrlBasedOnRoute = (
  route,
  defaultRedirect,
  program,
) => {
  if (route.includes('campaign')) {
    return getDemoSlotByProgram(program);
  }

  switch (route) {
    case EPathname.SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM:
    case EPathname.SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM_PPC_PAGE:
    case EPathname.SYSTEM_DESIGN:
      return 'https://rebrand.ly/crio-sd-on-demand';
    case EPathname.QA_AUTOMATION_TESTING:
    case EPathname.QA_PPC_PAGE:
      return 'https://rebrand.ly/crio-qa-on-demand';
    case EPathname.DATA_ANALYTICS:
    case EPathname.DATA_ANALYTICS_PPC_PAGE:
      return 'https://rebrand.ly/crio-data-analytics-on-demand';
    case EPathname.ACCELERATE:
      return 'https://rebrand.ly/crio-accelerate-on-demand';
    case '/':
      return getDemoSlotByProgram(program);
    default:
      return defaultRedirect;
  }
};

export const isYearGreaterThanCurrent = (selectedYear) => {
  const currentYear = new Date().getFullYear();
  return selectedYear >= currentYear;
};
