import { useState, useContext } from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import React from 'react';
import PageBar from './FormComponents/PageBar';
import PageOne from './FormPages/PageOne';
import PageTwo from './FormPages/PageTwo';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticQuery, graphql } from 'gatsby';
import {
  LEAD_GEN_AUTOMATION_EDGE_PATH,
  LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS,
  LEAD_GEN_PROGRAM_ID_FULL_STACK,
  LEAD_GEN_PROGRAM_ID_QA,
  LEAD_GEN_QA_AUTOMATION_PATH,
  trialTypes,
} from '../../constants/LeadGenerationConstants/index';
import { useLocation } from '@reach/router';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import db from '../../data/db.json';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';
import OpenFormPageOne from './FormPages/OpenFormPageOne';

export const pageQuery = graphql`
  query {
    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }
  }
`;

const getInitialPageNumber = (leadDocID) => {
  return leadDocID && typeof leadDocID === 'string' && leadDocID !== '' ? 2 : 1;
};

// Layout for lead-generation form and conditionally render page 1/2 of the form
export default function LeadGenerationFormComponent({
  closeDialog,
  mobile,
  buttonLocation,
  programType,
  isOnePageForm,
  onSubmitPageOne,
  pageOneButtonText,
  pageTwoButtonText,
  applicationPage,
  formHeading,
  isOpenForm,
  pageOneGradYear,
  pageOneWorkingProfessional,
  isFullStackPpcVl,
  hasProgramIntrestedField,
  customUtmParams,
  openFormClassName,
}) {
  const globalContext = useContext(GlobalStateContext);
  const initialPage = getInitialPageNumber(globalContext.globalLeadDocumentId);
  const [page, setPage] = useState(isOnePageForm ? 1 : initialPage);

  // router variables
  const location = useLocation();
  const pathName = location?.pathname;

  // Check if the current lead if of type QA
  const isMQALead =
    pathName === LEAD_GEN_QA_AUTOMATION_PATH ||
    pathName === LEAD_GEN_AUTOMATION_EDGE_PATH ||
    programType === LEAD_GEN_PROGRAM_ID_QA;
  const isSde2 = pathName === '/software-development-fellowship-program-plus/';

  const isAcceleratePage = pathName === '/accelerate/';
  const { isDataSciencePage } = useDataScienceProgram();

  // Demo Slots
  const allDemoSlots = db.strapi_metadata.demoSlots;
  const demoSlots = isSde2
    ? allDemoSlots.filter(
        (slot) => slot.TrialType === trialTypes.FELLOWSHIP_PLUS,
      )
    : isMQALead
    ? allDemoSlots.filter((slot) => slot.TrialType === trialTypes.MQA_TRIAL)
    : isAcceleratePage
    ? allDemoSlots.filter((slot) => slot.TrialType === trialTypes.ACCELERATE)
    : isDataSciencePage
    ? allDemoSlots.filter((slot) => slot.TrialType === trialTypes.DATA_SCIENCE)
    : allDemoSlots.filter(
        (slot) => slot.TrialType === trialTypes.FELLOWSHIP_TRIAL,
      );

  // current program ID required for fetch program details
  const currentProgramID = isMQALead
    ? LEAD_GEN_PROGRAM_ID_QA
    : isDataSciencePage
    ? LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS
    : LEAD_GEN_PROGRAM_ID_FULL_STACK;

  return (
    <StaticQuery
      query={pageQuery}
      render={(data) => {
        const allPrograms = data.allStrapiPrograms.nodes;
        const currentProgram = allPrograms.filter(
          (program) => program.PrimaryProgramID === currentProgramID,
        )[0];

        const slackChannel = currentProgram?.SlackChannel;
        const trialProgramId = currentProgram?.trialProgramId;

        return isOpenForm ? (
          <OpenFormPageOne
            hasProgramIntrestedField={hasProgramIntrestedField}
            closeDialog={closeDialog}
            programType={programType}
            trialProgramId={trialProgramId}
            buttonLocation={'OPEN_LEAD_FORM'}
            openFormClassName={openFormClassName}
            pageTwoButtonText={pageTwoButtonText}
            isOpenForm={isOpenForm}
            demoSlots={demoSlots}
            applicationPage={applicationPage}
            isFullStackPpcVl={isFullStackPpcVl}
            customUtmParams={customUtmParams}
          />
        ) : (
          <>
            <div className="relative flex h-min flex-col items-stretch gap-1.5 overflow-y-auto scrollbar-hide">
              {!mobile && !isOpenForm && (
                <IconButton
                  onClick={closeDialog}
                  aria-label="exit"
                  size="small"
                  className="right-0 ml-auto cursor-pointer text-v5-neutral-200 smallheight:absolute"
                >
                  <FontAwesomeIcon icon="fa-solid fa-xmark" />
                </IconButton>
              )}
              {mobile && (
                <Box className="flex justify-between">
                  <StaticImage
                    loading="eager"
                    src="../../images/Crio_Light.png"
                    placeholder="none"
                    alt="Crio.Do"
                    className="h-[30px] w-[80px]"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                  <IconButton
                    onClick={closeDialog}
                    aria-label="exit"
                    size="small"
                    className="ml-auto cursor-pointer text-v5-neutral-200"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                  </IconButton>
                </Box>
              )}
              <Typography
                className={`text-center font-rubik smallheight:mt-3 smallheight:text-[1.75rem] ${
                  isOpenForm
                    ? 'text-lg font-semibold'
                    : mobile
                    ? 'text-2xl'
                    : 'text-3xl'
                } text-v5-neutral-200 ${isOnePageForm ? 'mb-3' : ''}`}
              >
                {formHeading || 'Book Your Trial'}
              </Typography>
              {!isOnePageForm && <PageBar page={page} totalPages={2} />}
              {page === 1 ? (
                <PageOne
                  setPage={setPage}
                  buttonLocation={buttonLocation}
                  slackChannel={slackChannel}
                  isOnePageForm={isOnePageForm}
                  closeDialog={closeDialog}
                  onSubmitPageOne={onSubmitPageOne}
                  pageOneButtonText={pageOneButtonText}
                  isOpenForm={isOpenForm}
                  programType={programType}
                  applicationPage={applicationPage}
                  pageOneGradYear={pageOneGradYear}
                  pageOneWorkingProfessional={pageOneWorkingProfessional}
                  isFullStackPpcVl={isFullStackPpcVl}
                  customUtmParams={customUtmParams}
                />
              ) : (
                <PageTwo
                  closeDialog={closeDialog}
                  programType={programType}
                  trialProgramId={trialProgramId}
                  buttonLocation={buttonLocation}
                  pageTwoButtonText={pageTwoButtonText}
                  isOpenForm={isOpenForm}
                  demoSlots={demoSlots}
                  applicationPage={applicationPage}
                  isFullStackPpcVl={isFullStackPpcVl}
                  customUtmParams={customUtmParams}
                />
              )}
            </div>
          </>
        );
      }}
    ></StaticQuery>
  );
}
