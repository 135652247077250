import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const curriculumData = {
  curriculum: [
    {
      title: 'Programming Foundations',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/One.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '4 to 8 weeks',
      tag: null,
      data: [
        'Kickstart your Programmer journey by creating a solid foundation of programming and language skills. Start using standard industry developer technologies.',
      ],
      skills: null,
      anchor: 'programming-foundations',
      cardTitle: null,
      cards: [
        {
          image: null,
          data: [
            'Start with Hello World and get familiar with the syntax and constructs of Java or JavaScript based on the specialization you choose',
          ],
          skills: [
            'Loops',
            'Conditionals',
            'Arrays',
            'Objects',
            'Strings',
            'Data Types',
            'Libraries',
          ],
          title: 'Language Fundamentals',
        },
        {
          image: null,
          data: [
            'Gain skills that are must-haves for any developer through hands-on activities and live workshops.',
          ],
          skills: ['HTTP', 'Rest API', 'Deployment', 'Linux', 'GIT', 'SQL'],
          title: 'Developer Essentials',
        },
        {
          image: {
            childImageSharp: {
              gatsbyImageData: {
                layout: 'constrained',
                placeholder: {
                  fallback:
                    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC6ElEQVQ4y3WVS0hUURjH//eOEoEEbVtHC2lVi4LooYEwo7UIE3xMoahjvl8z99wZa0KyB6bO3DuNtUlqE20kwWgVBbULIiEqEBQJo1qIKzOI+4/7vlfHxeGec8/5fud7H0ATgKZUQlMWoSlb0AShKwY0YUA35yWGtWedoS1jyZoMuLBNX8A5qCv0vu7w1oLexd4lyqbFsuj2j21o7gFXQ4VyQWGkkKJkAT24YZ21NTQsWZuxCEtl3dnQA6boig2ZyhCT14mZdAAq7K8r4ypisgI+o70ZMDOXZnShlcWPMVY+67LWUkGEobowAgoRzg3+hmMmpjNsfNVC/jvM+T/VPPCll3L2CuWpYaKg+lBdhKG+33ygNfIqX6+eYW4jSiyrxEqKZfEoI92NRFF1TS4B3JUW9qFILsVj7xLEjyTxU6H0tI04WU001RMP9gDqHjCcFpI3F5TnEsSNRuL0ecqxGuJmD1FMO0AvZUoANWGDzDGjEoUMy8YTlMf7iOwAMdJBOZsgJnoJLaChF1TFBQYSNq8SOZWH5gZ5cKaPUrbL9pc5HmUIbYwVt9opz47YUF31I+2mUbAq9hdH+eRzDfm3kgNLF4i7vZR1R/Npleeet7N2OU6sJikt9FO6N+j60w+sCTMrAffH2PamkeQRTvyqI9ZTjNxpYPlQ3PLZPj3J6NerjHwfJtaSlJb6Wd5xkfLtXhMaMtmAmXdTwqh4O8SjvzuJdUGsJInmOkq1USKfolQUxLcksTZKbAji5TXiVBWlnhbiYcZA3jfZsKtCMfBYEB+SxPtuIllPHD9LNF22zcopxIsU8WmQmG8nLsWIE1WE0knMpkNAxwdmUIQh51PEYCsRrSPiDcTkiF0ZeWufmBwimhuIWB0x0EoUAgGxSs9rDk5jMB1r5pl5galZqMwUe21G3ZwX0zuag9gKty/dMd/vIjsbwc4u4863nfliuMGGc2onpNS6ZIMNPwF2ThqhbrzXE6DvfgL+AxeqJsiziNC8AAAAAElFTkSuQmCC',
                },
                backgroundColor: 'transparent',
                images: {
                  fallback: {
                    src: '/static/d55c8df9a40f9b3fe9147dc1b15cb291/9c1f1/Six.png',
                    srcSet:
                      '/static/d55c8df9a40f9b3fe9147dc1b15cb291/9c1f1/Six.png 300w,\n/static/d55c8df9a40f9b3fe9147dc1b15cb291/f159a/Six.png 600w',
                    sizes: '(min-width: 300px) 300px, 100vw',
                  },
                  sources: [
                    {
                      srcSet:
                        '/static/d55c8df9a40f9b3fe9147dc1b15cb291/5de9a/Six.webp 300w,\n/static/d55c8df9a40f9b3fe9147dc1b15cb291/f0b89/Six.webp 600w',
                      type: 'image/webp',
                      sizes: '(min-width: 300px) 300px, 100vw',
                    },
                  ],
                },
                width: 300,
                height: 300,
              },
            },
          },
          data: null,
          skills: null,
          title: null,
        },
      ],
    },
    {
      title: 'Data Structures and Algorithms',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Two.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '20 weeks',
      tag: null,
      data: [],
      skills: [],
      anchor: 'dsa',
      cardTitle: null,
      options: [
        { tag: 'dsa-basics', title: 'Basic' },
        { tag: 'dsa-intermediate', title: 'Intermediate' },
        { tag: 'dsa-advanced', title: 'Advanced' },
      ],
      cards: [
        {
          data: [
            '<div>Build problem solving skills with the commonly used Data Structures. Learn the basics of <strong class="">Space and Time complexity</strong> which drive optimization.<ul><li>Programming Essentials</li><li>Develop logic building skills by solving simple math, recursion, implementation problems</li><li>Learn Time & Space complexity</li><li>Get introduced to data structures & traversals - Linkedlist & Trees</li></ul></div>',
          ],
          skills: [
            'Logic Building',
            'Collections',
            'Space and Time Complexity',
          ],
          title: 'DSA Basics',
          subCards: null,
          tag: 'dsa-basics',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Learn various patterns of problem solving in arrays & strings and apply them in a wide variety of problems.</li><li>Learn the art of debugging that is critical for your timed interviews.</li></ul>',
          ],
          title: 'Arrays & Strings',
          skills: [
            'Matrix',
            'Two Pointers',
            'Sliding Window',
            'Sorting',
            'Prefix Sum',
            'Bit Manipulation',
            'Binary Search',
          ],
          tag: 'dsa-intermediate',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Learn Linked List implementation and applications</li><li>Apply Queue, Stack to solve real world problems</li></ul>',
          ],
          title: 'Stack, Queue and Linked List',
          skills: [
            'Data Structure Application',
            'Linked List',
            'Stack',
            'Queue',
          ],
          tag: 'dsa-intermediate',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Apply Hash to solve real world problems</li><li>Learn BFS/DFS to solve multiple Tree problems effectively</li></ul>',
          ],
          title: 'Hash, Trees',
          skills: [
            'Data Structure Application',
            'Hash',
            'Binary Tree',
            'Binary Search Tree',
            'BFS',
            'DFS',
            'Recursion',
          ],
          tag: 'dsa-intermediate',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Deep dive into graph problem patterns and implement common algorithms like Prim’s, Kruskal’s and Dijkstra\'s</li><li>Learn common problem solving approaches like greedy & backtracking</li></ul>',
          ],
          title: 'Heap, Graphs, Greedy and Backtracking',
          skills: [
            'Graph BFS',
            'Graph DFS',
            'Heaps',
            'Shortest Path',
            'Graph Partitioning',
            'Greedy Method',
            'Advanced recursion and backtracking',
            'Topological Sort',
          ],
          tag: 'dsa-advanced',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Learn the art of DP and practice problems using Memoization and Tabulation</li><li>Get introduced to advanced DS like Trie, Segment Trees and solve related problems</li></ul>',
          ],
          title: 'Dynamic Programming and Advanced DS',
          skills: [
            'DP Memoization',
            'DP Tabulations',
            'Knapsack',
            'Spanning Tree',
            'Trie',
            'Segment Tree',
          ],
          tag: 'dsa-advanced',
        },
      ],
    },
    {
      title: 'Tech Stack Specialization & Work-like Projects',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Three.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '16 to 28 weeks',
      tag: null,
      data: [],
      skills: [],
      anchor: 'tech-stack',
      cardTitle: null,
      options: [
        { tag: 'fullstack-specialization', title: 'Fullstack Specialization' },
        { tag: 'backend-specialization', title: 'Backend Specialization' },
      ],
      cards: [
        {
          data: [
            '<ul style="padding-left:20px"><li>Build a responsive front end for <strong>QTrip</strong>, an AirBnB-like travel planning website, and learn HTML, CSS, Bootstrap and more</li></ul>',
          ],
          skills: ['HTML', 'CSS', 'Flexbox', 'Bootstrap'],
          title:
            'Build an AirBnB-like static frontend to master HTML, CSS & more',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Add life to the web pages of <strong>QTrip</strong> by making them dynamic and by integrating with a backend and 3rd party plugins.</li></ul>',
          ],
          skills: [
            'API Calls',
            'Routing',
            'Filters',
            'Data Persistence',
            '3rd Party Libraries',
          ],
          title:
            'Build an AirBnB-like dynamic frontend to master Rest API, Routing, Filters & more',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply your learnings from QTrip Static and QTrip Dynamic to build your first independent project, <strong>XBoard</strong> - a newsfeed that aggregates articles from popular media outlets.</li></ul>',
          ],
          skills: [
            'JavaScript',
            'DOM Manipulation',
            'Developer Tools',
            'Binary Search Tree',
            'REST',
            'Deployment',
          ],
          title: 'Build a Google News-like newsfeed aggregator',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Build the frontend for <strong>QKart</strong> - an online ecommerce store to learn React, and create a rich shopping experience.</li></ul>',
          ],
          skills: [
            'Components',
            'Component lifecycle',
            'Component state management',
            'Component styling',
            'Props',
            'Routing',
            'React Hooks',
            'Material UI',
          ],
          title:
            'Build a React powered frontend for an Amazon-like shopping app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply your learnings in React to build an independent project, the frontend of <strong>QTify</strong> - an audio-streaming platform like Spotify.</li></ul>',
          ],
          skills: ['Figma', 'Material UI', 'React', 'REST API', 'Deployment'],
          title: 'Build the frontend for a Spotify like app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Build a scalable NodeJS backend for <strong>QKart</strong> using the Express.js framework</li></ul>',
          ],
          skills: ['Express JS', 'Node JS', 'MongoDB', 'REST API'],
          title: 'Build a Node JS powered backend for a Flipkart-like app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply your learnings from QKart Backend to build your second independent project using Express and Node JS, the backend for <strong>XFlix</strong> - a video streaming platform like Netflix.</li></ul>',
          ],
          skills: [
            'Express JS',
            'Node JS',
            'MongoDB',
            'REST API',
            'Deployment',
          ],
          title: 'Build the backend for a Netflix-like OTT app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply Java and OOP knowledge to build a Calculator application <strong>QCalc</strong></li></ul>',
          ],
          skills: ['Java', 'OOP', 'Classes', 'Packages', 'Git', 'Gradle'],
          title: 'Start by building your first multi file Java project',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply all your Java knowledge to independently build a <strong>XURL</strong> - a URL shortener application like bit.ly.</li></ul>',
          ],
          skills: ['Data Structures', 'Java', 'OOP', 'Classes'],
          title: 'Implement Java skills to build a Bit.ly like URL shortener',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Start your backend development journey by building <strong>QMoney</strong>, a visual stock portfolio analyser.</li></ul>',
          ],
          skills: [
            'OOP',
            'JSON',
            'Consuming REST API',
            'Refactoring',
            'Gradle',
            'Exception Handling',
            'JUnit',
            'Jackson',
          ],
          title:
            'Build the backend for a stock portfolio analyser like Zerodha',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Model classes and relationships to build a company hierarchy with specified constraints - <strong>XCompany</strong></li></ul>',
          ],
          skills: ['Object Modeling', 'Machine Coding'],
          title:
            'Problem solve by applying Object Modeling and Machine Coding skills',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Build a scalable, distributed, Java backend for <strong>QEats</strong> - a Swiggy-like food ordering app.</li></ul>',
          ],
          skills: [
            'MVCS',
            'Spring Boot',
            'REST APIs',
            'MongoDB',
            'Mockito',
            'Scientific Debugging',
            'Spring Security',
          ],
          title: 'Build a scalable backend for a Swiggy-like app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply your learnings from the entire program so far to build a fully functional Java backend for <strong>XMeme</strong>(a page where users can post and view Memes).</li></ul>',
          ],
          skills: ['Backend', 'REST API', 'SpringBoot', 'MongoDB'],
          title: 'Apply your learnings to build a Giphy like app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply Java and OOP knowledge to build a Calculator application <strong>QCalc</strong></li></ul>',
          ],
          skills: ['Java', 'OOP', 'Classes', 'Packages', 'Git', 'Gradle'],
          title: 'Start by building your first multi file Java project',
          tag: 'backend-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply all your Java knowledge to independently build a <strong>XURL</strong> - a URL shortener application like bit.ly.</li></ul>',
          ],
          skills: ['Data Structures', 'Java', 'OOP', 'Classes'],
          title: 'Implement Java skills to build a Bit.ly like URL shortener',
          tag: 'backend-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Start your backend development journey by building <strong>QMoney</strong>, a visual stock portfolio analyser.</li></ul>',
          ],
          skills: [
            'OOP',
            'JSON',
            'Consuming REST API',
            'Refactoring',
            'Gradle',
            'Exception Handling',
            'JUnit',
            'Jackson',
          ],
          title:
            'Build the backend for a stock portfolio analyser like Zerodha',
          tag: 'backend-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Model classes and relationships to build a company hierarchy with specified constraints - <strong>XCompany</strong></li></ul>',
          ],
          skills: ['Object Modeling', 'Machine Coding'],
          title:
            'Problem solve by applying Object Modeling and Machine Coding skills',
          tag: 'backend-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Build a scalable, distributed, Java backend for <strong>QEats</strong> - a Swiggy-like food ordering app.</li></ul>',
          ],
          skills: [
            'MVCS',
            'Spring Boot',
            'REST APIs',
            'MongoDB',
            'Mockito',
            'Scientific Debugging',
            'Spring Security',
          ],
          title: 'Build a scalable backend for a Swiggy-like app',
          tag: 'backend-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply your learnings from the entire program so far to build a fully functional Java backend for <strong>XMeme</strong>(a page where users can post and view Memes).</li></ul>',
          ],
          skills: ['Backend', 'REST API', 'SpringBoot', 'MongoDB'],
          title: 'Apply your learnings to build a Giphy like app',
          tag: 'backend-specialization',
        },
      ],
    },
    {
      title: 'Tech Stack Specialisation (Advanced)',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Four.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '4 to 8 weeks',
      tag: null,
      data: [],
      skills: [],
      anchor: 'tech-stack-advanced',
      cardTitle: null,
      options: [
        { tag: 'frontend-advanced', title: 'Frontend Advanced' },
        { tag: 'backend-advanced', title: 'Backend Advanced' },
      ],
      cards: [
        {
          data: [
            '<ul style="padding-left:20px"><li>Master essential concepts required to be a strong FE developer through a series of hands-on workshops, activities and projects.</li><li>Learn concepts like <strong>Caching, Security, Website performance optimization, Redux State Management, Server Side Rendering</strong> using NextJS and others</li></ul>',
          ],
          skills: ['Redux', 'NextJS', 'CDN', 'Advanced CSS', 'SEO'],
          spanToWidth: true,
          title: null,
          tag: 'frontend-advanced',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Make the Java backend for <strong>QEats</strong>, scale-able</li><li>Build advanced search features, solve for performance bottlenecks and improve REST API performance</li></ul>',
          ],
          skills: ['Caching', 'Redis', 'JMeter', 'Multi-threading'],
          spanToWidth: true,
          title:
            'Work on a Swiggy like application and improve its performance',
          tag: 'backend-advanced',
        },
      ],
    },
    {
      title: 'System Design',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Five.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '8 weeks',
      tag: null,
      data: [],
      skills: [],
      anchor: 'system-design',
      cardTitle: null,
      options: [
        { tag: 'lld-foundation', title: 'LLD Foundations' },
        { tag: 'hld-foundation', title: 'HLD Foundations' },
      ],
      cards: [
        {
          image: null,
          data: [
            '<ul style="padding-left:20px"><li>Build <strong>QContest</strong>, a hackerrank-like contest platform by applying your <strong>Core Java</strong> and Low Level design skills</li></ul>',
          ],
          title:
            'Build a Hackerrank-like App to master Basics of Low Level Design',
          skills: [
            'SOLID Principles',
            'Object Oriented Design',
            'Object Modeling',
            'Clean Architecture',
            'Design Patterns',
          ],
          tag: 'lld-foundation',
        },
        {
          image: null,
          data: [
            '<ul style="padding-left:20px"><li>Build the JukeBox application, which can play songs from a playlist, using clean architecture</li></ul>',
          ],
          title:
            'Create an Spotify like application by applying machine coding techniques',
          skills: ['OOP', 'Machine Coding'],
          tag: 'lld-foundation',
        },
        {
          data: [
            '<div>Understand essential components/concepts of a modern tech stack used universally by internet companies with bonus hands-on exercises</div>',
          ],
          skills: [
            'Architecture of Web Applications',
            'Docker',
            'Kafka',
            'Micro-Services',
            'CI/CD',
            'Cloud',
            'CAP Theorem',
            'SQL/NoSQL',
            'Scalability',
            'Load Balancing',
            'Partitioning',
            'Caching',
          ],
          title: 'HLD Foundations',
          tag: 'hld-foundation',
        },
      ],
    },
    {
      title: 'Interview Blitz',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Six.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '4 weeks',
      tag: null,
      data: [
        'Practice multiple timed mock assessments',
        'Perfect the art of cracking DSA interviews through multiple DSA Blitz sessions',
        'Prepare to defend every single skill keyword added to your resume',
      ],
      skills: [
        'DSA Mocks',
        'DSA Blitz',
        'P2P Interviews',
        'Take home Prep',
        'Specialization Specific Prep',
      ],
      anchor: 'interview',
      cardTitle: null,
      cards: null,
    },
    {
      title: 'Crio Externship',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Seven.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '4 weeks',
      tag: null,
      data: [
        'Unlike regular boot camps where you wrap up your learnings with a Capstone project, we go one step further. You will actually work on a real project statement given by some of the top tech companies in India. Here are some of the past companies who provided externships for our learner community,',
      ],
      skills: [
        'CRED',
        'Jumbotail',
        'Groww',
        'Vicara',
        'Slice',
        'Recko',
        'and many more..',
      ],
      anchor: 'externship',
      cardTitle: null,
      cards: null,
    },
  ],
  advancedCurriculum: [
    {
      title: 'Advanced Backend Engineering',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/One.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '4 weeks',
      tag: null,
      data: [
        'Build advanced features for QEats, a Swiggy-like food ordering app. Solve for performance bottlenecks in the backend and improve REST API performance.',
      ],
      skills: [
        'Spring Security',
        'Caching',
        'Redis',
        'JMeter',
        'Multi-threading',
      ],
      cardTitle: null,
      cards: null,
    },
    {
      title: 'Advanced System Design',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Two.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '6 weeks',
      tag: null,
      data: [
        'Master Low Level Design and get an introduction into various components/concepts in High Level Design through workshops, projects, and hands-on activities.',
      ],
      skills: null,
      cardTitle:
        'Curriculum to crack System Design interviews for super-dream jobs',
      cards: [
        {
          image: null,
          data: [
            '<p>Build a Hackerrank like contest platform by applying your Core Java and Low Level design skills<ul><li>Design & implement the classes required for a contest to work.</li><li>Implement the business logic of running a simulated contest.</li></ul></p>',
          ],
          skills: [
            'Machine Coding',
            'SOLID Principles',
            'Object Oriented Design',
            'Clean Architecture',
            'Design Patterns',
            'Schema Design',
          ],
          title: 'Build a Hackerrank-like App to master Low Level Design',
        },
        {
          image: null,
          data: [
            'Learn the art of cracking Machine Coding rounds with solid Low Level Design(LLD) skills',
          ],
          skills: null,
          title: 'Build Low Level Design for a iTunes-like App',
        },
        {
          image: null,
          data: [
            'Understand essential components/concepts of a modern tech stack used universally by internet companies with bonus hands-on exercises.',
          ],
          skills: [
            'Docker',
            'Kafka',
            'CDN',
            'Load Balancer',
            'Deployment',
            'Micro-Services',
            'CI/CD',
            'Cloud',
          ],
          title: 'High Level Design Concepts',
        },
        {
          image: {
            childImageSharp: {
              gatsbyImageData: {
                layout: 'constrained',
                placeholder: {
                  fallback:
                    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC6ElEQVQ4y3WVS0hUURjH//eOEoEEbVtHC2lVi4LooYEwo7UIE3xMoahjvl8z99wZa0KyB6bO3DuNtUlqE20kwWgVBbULIiEqEBQJo1qIKzOI+4/7vlfHxeGec8/5fud7H0ATgKZUQlMWoSlb0AShKwY0YUA35yWGtWedoS1jyZoMuLBNX8A5qCv0vu7w1oLexd4lyqbFsuj2j21o7gFXQ4VyQWGkkKJkAT24YZ21NTQsWZuxCEtl3dnQA6boig2ZyhCT14mZdAAq7K8r4ypisgI+o70ZMDOXZnShlcWPMVY+67LWUkGEobowAgoRzg3+hmMmpjNsfNVC/jvM+T/VPPCll3L2CuWpYaKg+lBdhKG+33ygNfIqX6+eYW4jSiyrxEqKZfEoI92NRFF1TS4B3JUW9qFILsVj7xLEjyTxU6H0tI04WU001RMP9gDqHjCcFpI3F5TnEsSNRuL0ecqxGuJmD1FMO0AvZUoANWGDzDGjEoUMy8YTlMf7iOwAMdJBOZsgJnoJLaChF1TFBQYSNq8SOZWH5gZ5cKaPUrbL9pc5HmUIbYwVt9opz47YUF31I+2mUbAq9hdH+eRzDfm3kgNLF4i7vZR1R/Npleeet7N2OU6sJikt9FO6N+j60w+sCTMrAffH2PamkeQRTvyqI9ZTjNxpYPlQ3PLZPj3J6NerjHwfJtaSlJb6Wd5xkfLtXhMaMtmAmXdTwqh4O8SjvzuJdUGsJInmOkq1USKfolQUxLcksTZKbAji5TXiVBWlnhbiYcZA3jfZsKtCMfBYEB+SxPtuIllPHD9LNF22zcopxIsU8WmQmG8nLsWIE1WE0knMpkNAxwdmUIQh51PEYCsRrSPiDcTkiF0ZeWufmBwimhuIWB0x0EoUAgGxSs9rDk5jMB1r5pl5galZqMwUe21G3ZwX0zuag9gKty/dMd/vIjsbwc4u4863nfliuMGGc2onpNS6ZIMNPwF2ThqhbrzXE6DvfgL+AxeqJsiziNC8AAAAAElFTkSuQmCC',
                },
                backgroundColor: 'transparent',
                images: {
                  fallback: {
                    src: '/static/d55c8df9a40f9b3fe9147dc1b15cb291/9c1f1/Six.png',
                    srcSet:
                      '/static/d55c8df9a40f9b3fe9147dc1b15cb291/9c1f1/Six.png 300w,\n/static/d55c8df9a40f9b3fe9147dc1b15cb291/f159a/Six.png 600w',
                    sizes: '(min-width: 300px) 300px, 100vw',
                  },
                  sources: [
                    {
                      srcSet:
                        '/static/d55c8df9a40f9b3fe9147dc1b15cb291/5de9a/Six.webp 300w,\n/static/d55c8df9a40f9b3fe9147dc1b15cb291/f0b89/Six.webp 600w',
                      type: 'image/webp',
                      sizes: '(min-width: 300px) 300px, 100vw',
                    },
                  ],
                },
                width: 300,
                height: 300,
              },
            },
          },
          data: null,
          skills: null,
          title: null,
        },
      ],
    },
  ],
};
