import React, { useContext, useEffect, useState } from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import GreenBanner from '../GreenBanner/index';
import CrioButton from '../CrioButton/index';
import { useDialog } from '../Dialog/index';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { useCustomToast } from '@components/extra/useCustomToast';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config.js';
import RawHTML from '@components/Common/RawHTML/index';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import FspCarousel from './FspCarousel/index';
import Plus from '../../../images/plus.svg';
import AdvancedCurriculum from './AdvancedCurriculum/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_DOWNLOAD_CURRICULUM } from '@src/constants/LeadGenerationConstants/index';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { EButtonType } from '@src/constants/data/programs/index';
import { useInView } from 'react-intersection-observer';
import useResizer from '@components/extra/useResizer';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';
import { cn } from '@/lib/utils';

const fullConfig = resolveConfig(tailwindConfig);

const FspCurriculumSection = React.memo(function FspCurriculumSection({
  type,
  curriculum,
  dreamCompanies,
  superDreamCompanies,
  advancedCurriculum,
  headingClassName,
  isTypeQA,
  isTypeFellowShipPlus,
  advancedCurriculumHeading,
  hideHeader,
  hideAdvanceCurriculumSection,
  ...props
}) {
  const [openDialog, closeDialog] = useDialog();
  const location = useLocation();
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const downloadCurriculumType = isTypeFellowShipPlus
    ? 'FELLOWSHIP_PLUS'
    : isTypeQA
    ? 'QA_AUTOMATION_TESTING'
    : 'FELLOWSHIP';

  const { isDataSciencePage } = useDataScienceProgram();

  console.log('🚀 ~ advancedCurriculumHeading:', advancedCurriculumHeading);

  const triggerSuccessToast = useCustomToast(
    'success',
    <div className="">
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-xs">
        You will receive the curiculum in your email shortly.
        <br /> (In case you have your adblocker turned on, please write to us at{' '}
        <a href="mailto:programs@criodo.com" className="font-bold">
          programs@criodo.com
        </a>{' '}
        to receive the curriculum)
      </p>
    </div>,
  );

  //Monitoring elements within the viewport to record them for WebEngage events.
  const isMobile = useResizer();

  const [curriculumSection1Ref, inViewCurriculumSection1] = useInView({
    triggerOnce: true,
    threshold: 1.0,
  });
  const [curriculumSection2Ref, inViewCurriculumSection2] = useInView({
    triggerOnce: true,
    threshold: 1.0,
  });
  const [curriculumSection3Ref, inViewCurriculumSection3] = useInView({
    triggerOnce: true,
    threshold: 1.0,
  });

  // Consolidating into a single useEffect is avoided due to the concern
  // of pushing multiple instances of the same event into the dataLayer.

  useEffect(() => {
    if (inViewCurriculumSection1) {
      GTM.track(gtmEvents.SCROLL_TO_CURRICULUM_SECTION, {
        type: type,
        location: 'CURRICULLUM_SECTION_1',
      });
    }
  }, [inViewCurriculumSection1]);

  useEffect(() => {
    if (inViewCurriculumSection2) {
      GTM.track(gtmEvents.SCROLL_TO_CURRICULUM_SECTION, {
        type: type,
        location: 'CURRICULLUM_SECTION_2',
      });
    }
  }, [inViewCurriculumSection2]);

  useEffect(() => {
    if (inViewCurriculumSection3) {
      GTM.track(gtmEvents.SCROLL_TO_CURRICULUM_SECTION, {
        type: type,
        location: 'CURRICULLUM_SECTION_3',
      });
    }
  }, [inViewCurriculumSection3]);

  const triggerCurriculumWebengageFlow = (email, phone, type) => {
    GTM.login({
      userId: email,
      email: email,
      phone: phone,
      whatsappNumber: phone,
    });
    GTM.setEmail(email);

    if (phone) {
      GTM.track(gtmEvents.PHONE_SUBMITTED, {
        phone: phone,
        type: 'DOWNLOAD_CURRICULUM_STRIP',
      });
    }

    window.webengage &&
      window.webengage.user.setAttribute('we_whatsapp_opt_in', true);

    //GTM Event for Email Capture
    if (!state.isEmailEventSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: downloadCurriculumType,
        location: 'DOWNLOAD_CURRICULUM_STRIP',
      });
      dispatch({
        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
        payload: true,
      });
    }

    GTM.track(gtmEvents.DOWNLOAD_CURRICULUM_APPLIED_SUCCESSFULLY, {
      type: downloadCurriculumType,
      location: 'STRIP',
    });

    if (type == EButtonType.DOWNLOAD_CURRICULUM_BUTTON) {
      GTM.leadSquaredEvent(
        ELeadSquaredActivityCode.SD_PAGE_DOWNLOAD_CURRICULUM_CLICKED,
        email,
      );
    } else {
      GTM.leadSquaredEvent(
        ELeadSquaredActivityCode.SD_PAGE_DOWNLOAD_CURRICULUM_SUBMITTED,
        email,
      );
    }

    triggerSuccessToast();
  };

  const downloadCareerHandbook = () => {
    openDialog({
      children: (
        <div
          onClose={closeDialog}
          open={true}
          className="w-full min-w-[300px]  p-6 md:min-w-[500px]"
        >
          <DialogTitle className="p-0">
            <h4>Program Curriculum</h4>
          </DialogTitle>{' '}
          <form
            className="w-full"
            onSubmit={(e) => {
              e.preventDefault();
              const email = e.target.email.value;
              const phone = e.target.phone.value;
              if (phone.length != 10) {
                alert('Enter a valid phone number.');
                return;
              }

              dispatch({
                type: 'SET_EMAIL',
                payload: email,
              });
              GTM.setEmail(email);
              triggerCurriculumWebengageFlow(email, phone, type);
              closeDialog();
            }}
          >
            <FormControl className="w-full">
              <InputBase
                defaultValue={state.email}
                sx={{ p: 1 }}
                inputProps={{ 'aria-label': 'Apply to Crio' }}
                color="yellow"
                size="normal"
                className={`mb-4 w-full flex-1 rounded-[20px] border-b border-gray-300 bg-white  text-black`}
                id="filled-basic"
                autoFocus
                placeholder="Email"
                type="email"
                name="email"
              />
              <InputBase
                sx={{ p: 1 }}
                inputProps={{
                  'aria-label': 'Apply to Crio',
                }}
                color="yellow"
                size="normal"
                className={`mb-4 w-full flex-1 rounded-[20px] border-b border-gray-300 bg-white  text-black`}
                id="filled-basic"
                autoFocus
                placeholder="Phone Number"
                type="number"
                name="phone"
                required
              />
              <CrioButton variant="primary">Download Now</CrioButton>
            </FormControl>
          </form>
        </div>
      ),
    });
  };

  let firstCurriculum = [],
    optionCurriculum = [],
    secondCurriculum = [];

  for (let i = 0; i < curriculum?.length; i++) {
    if (curriculum[i]?.tag?.includes('OPTION_')) {
      optionCurriculum.push(curriculum[i]);
      continue;
    }
    if (optionCurriculum.length == 0) {
      firstCurriculum.push(curriculum[i]);
    } else {
      secondCurriculum.push(curriculum[i]);
    }
  }

  const curriculumSection = isTypeQA ? curriculum : firstCurriculum;
  const dreamJobRange = isDataSciencePage
    ? '6 To 10 LPA'
    : props.dreamJobRange || '6 To 15 LPA';
  const superDreamJobRange = props.superDreamJobRange || '15 To 40 LPA';

  return (
    <SectionLayout
      headingClassName={headingClassName}
      heading={
        hideHeader
          ? ''
          : 'An All-round Effective Way to Supercharge your Tech Career'
      }
      description={
        hideHeader ? (
          <></>
        ) : (
          <>
            <strong className="font-bold text-black">
              Hands-on, work experience-based learning
            </strong>{' '}
            is at the heart of everything you do at Crio. Build software
            projects like the top 1% Tech Professionals and{' '}
            <strong className="font-bold text-black">
              learn all the skills
            </strong>{' '}
            you need to{' '}
            <strong className="font-bold text-black">land the best</strong>{' '}
            Fullstack/Backend Developers or SDET Jobs.
          </>
        )
      }
      {...props}
    >
      <div className="container ">
        {hideHeader ? (
          <></>
        ) : (
          <div className="mb-16 flex justify-center">
            <GreenBanner
              gradient={props.gradient}
              quote={props.quote}
              postContent={
                <>
                  <LeadGenerationButton
                    type="Download"
                    text="Download Curriculum"
                    isOnePageForm
                    formHeading="Program Curriculum"
                    pageOneButtonText="Download Now"
                    buttonLocation={LEAD_GEN_DOWNLOAD_CURRICULUM}
                    onSubmitPageOne={triggerCurriculumWebengageFlow}
                    clickEvent={() => {
                      GTM.track(gtmEvents.DOWNLOAD_CURRICULUM_CLICKED, {
                        type: type,
                        location: 'STRIP',
                      });
                    }}
                    {...(state.email &&
                      state.phone && {
                        onClick: () => {
                          triggerCurriculumWebengageFlow(
                            state.email,
                            state.phone,
                            EButtonType.DOWNLOAD_CURRICULUM_BUTTON,
                          );
                        },
                      })}
                  ></LeadGenerationButton>
                </>
              }
            ></GreenBanner>
          </div>
        )}

        {!props.hideCurriculumSection && (
          <div
            className={cn(
              'rounded-[50px] bg-v5-green-500 p-4  text-white  sm:p-6 ',
              `${hideAdvanceCurriculumSection ? '' : 'mb-20'}`,
            )}
          >
            <h1 className="my-10 text-center font-manrope text-2xl font-extrabold capitalize leading-tight sm:text-4xl">
              Curriculum to crack dream jobs{' '}
              <br className="hidden sm:block"></br>({dreamJobRange}) in top
              Product-Based <br className="hidden sm:block"></br>companies
            </h1>
            <div className="mb-16" ref={curriculumSection1Ref}>
              <FspCarousel data={dreamCompanies} {...props}></FspCarousel>
            </div>
            <div className="flex w-full flex-wrap items-stretch gap-y-4  gap-x-4 text-v5-neutral-200">
              {curriculumSection.map((ele, idx) => {
                return (
                  <div className="w-full" key={idx} id={ele?.anchor}>
                    <CustomAccordion
                      data={ele}
                      open={location.hash == '#' + ele?.anchor}
                    ></CustomAccordion>
                  </div>
                );
              })}

              {!isTypeQA && optionCurriculum.length ? (
                <div className="flex w-full flex-wrap items-stretch gap-4 sm:flex-nowrap ">
                  <div
                    className=" w-full sm:w-[49%]"
                    ref={isMobile ? curriculumSection2Ref : null}
                  >
                    <div ref={!isMobile ? curriculumSection2Ref : null}>
                      <CustomAccordion
                        data={optionCurriculum[0]}
                        open={
                          location.hash == '#' + optionCurriculum[0]?.anchor
                        }
                      ></CustomAccordion>
                    </div>
                    <div className="my-2 flex w-full justify-center">
                      <Plus className="w-[20px] "></Plus>
                    </div>
                    <div>
                      <CustomAccordion
                        data={optionCurriculum[1]}
                        open={
                          location.hash == '#' + optionCurriculum[1]?.anchor
                        }
                      ></CustomAccordion>
                    </div>
                  </div>
                  <div className="w-full sm:w-[49%]">
                    <div>
                      <CustomAccordion
                        data={optionCurriculum[2]}
                        open={
                          location.hash == '#' + optionCurriculum[2]?.anchor
                        }
                      ></CustomAccordion>
                    </div>
                    <div className="my-2 flex w-full justify-center">
                      <Plus className="w-[20px] "></Plus>
                    </div>
                    <div>
                      <CustomAccordion
                        data={optionCurriculum[3]}
                        open={
                          location.hash == '#' + optionCurriculum[3]?.anchor
                        }
                      ></CustomAccordion>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {!isTypeQA &&
                secondCurriculum.map((ele, idx) => {
                  return (
                    <div
                      className="w-full"
                      key={idx}
                      id={ele?.anchor}
                      ref={curriculumSection3Ref}
                    >
                      <CustomAccordion
                        data={ele}
                        open={location.hash == '#' + ele?.anchor}
                      ></CustomAccordion>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {!(props.hideCurriculumSection || hideAdvanceCurriculumSection) && (
          <AdvancedCurriculum
            gradient={props.gradient}
            superDreamJobRange={superDreamJobRange}
            CustomAccordion={CustomAccordion}
            data={superDreamCompanies}
            curriculum={advancedCurriculum}
            heading={advancedCurriculumHeading}
            dark={props.dark}
          ></AdvancedCurriculum>
        )}
      </div>
    </SectionLayout>
  );
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `none`,

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: '100%',
    height: props.isExpanded ? '100%' : 'auto',
    overflow: 'hidden',
    background: fullConfig.theme.colors['v5']['green']['700'],
    // border: props.isExpanded ? '1px solid white !important' : '',
  }),
  content: {
    margin: '8px 0px',
  },
}));

export const CustomAccordion = ({ open, data, gradient, variant }) => {
  const [expanded, setExpanded] = useState(open || false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const classes = useStyles({ isExpanded: expanded });
  let cards = data?.cards || [];
  const image = getImage(data?.image);

  function extractTextFromHTML(htmlString) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || '';
  }

  const handleChange = (event, isExpanded) => {
    if (isExpanded && !dropdownOpened) {
      const extractedTitle = extractTextFromHTML(data?.title);

      // Track GTM event
      GTM.track(gtmEvents.CURRICULLUM_DROPDOWN_CLICK, {
        curriculumSection: extractedTitle,
        action: 'open',
        type: 'FELLOWSHIP',
      });

      // Set dropdownOpened to true to avoid tracking multiple times
      setDropdownOpened(true);
    }

    setExpanded(isExpanded);
  };

  const getBadge = (type) => {
    switch (type) {
      case 'FREE_TRIAL':
        return (
          <div className="mb-3 w-max min-w-[80px] rounded-[5px] bg-v5-yellow-200 p-1 px-2 text-center text-xs font-semibold uppercase text-v5-neutral-600">
            Free Trial
          </div>
        );
      case 'OPTIONAL':
        return (
          <div className="mb-3 w-max min-w-[80px] rounded-[5px] bg-v5-yellow-200 p-1 px-2 text-center text-xs font-semibold uppercase text-v5-neutral-600">
            Optional
          </div>
        );
      case 'OPTION_1':
        return (
          <div className="mb-3 w-max min-w-[80px] rounded-[5px] bg-v5-lime-400 p-1 px-2 text-center text-xs font-semibold uppercase text-v5-neutral-600">
            Option 1
          </div>
        );
      case 'OPTION_2':
        return (
          <div className="mb-3 w-max min-w-[80px] rounded-[5px] bg-v5-lime-200 p-1 px-2 text-center text-xs font-semibold uppercase text-v5-neutral-600">
            Option 2
          </div>
        );
      default:
        return <></>;
    }
  };

  if (variant === 'FAQ') {
    return (
      <FAQCustomAccordion
        data={data}
        expanded={expanded}
        handleChange={handleChange}
        classes={classes}
        image={image}
      />
    );
  }

  return data ? (
    <Accordion
      disableGutters
      expanded={expanded}
      elevation={0}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit: true }}
      className={`rounded-[20px] md:rounded-[30px] ${
        gradient
          ? 'border-gradient-radius30 border-gradient bg-black bg-opacity-30'
          : 'bg-v5-green-700'
      }`}
    >
      <AccordionSummary
        className={`w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6`}
        expandIcon={
          <div className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300">
            <FontAwesomeIcon
              icon={['fas', 'chevron-right']}
              className={`w-[10px] rotate-90 transition-all`}
            ></FontAwesomeIcon>
          </div>
        }
        classes={{ content: classes.content }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div>
          <div>{getBadge(data?.tag)}</div>
          <div className="flex items-start gap-x-4">
            <div className="w-[40px]  min-w-[40px]">
              <GatsbyImage
                placeholder="blurred"
                loading="lazy"
                image={image}
                alt={'Curriculum'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <div>
              <h6 className={`transition-all  sm:w-full`}>
                <RawHTML>{data?.title}</RawHTML>
              </h6>
              <p className="text-sm text-v5-neutral-300">{data?.duration}</p>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={`${
          gradient ? '' : 'bg-v5-green-700'
        }  rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6`}
      >
        <div className="md:pl-[54px]">
          {data.description && (
            <div className="mb-4 text-v5-neutral-200">{data.description}</div>
          )}
          <div className="">
            {data.data.map((ele, idx) => (
              <div key={idx}>
                <div className="mb-2 flex items-start gap-x-2">
                  {data.data.length > 1 && (
                    <div className="w-[18px] min-w-[18px]">
                      <StaticImage
                        width={18}
                        loading="lazy"
                        src="../../../images/v5/ProgramCards/tick.png"
                        placeholder="none"
                        alt="Tick"
                        className="mt-1"
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    </div>
                  )}
                  <div>
                    <p>
                      <RawHTML>{ele}</RawHTML>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {data?.skills?.length > 0 && (
            <div className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3">
              {data?.skills?.map((ele, idx) => (
                <div
                  key={idx}
                  className="rounded-[5px] border-2 border-v5-neutral-300 p-1 px-3"
                >
                  <h6 className=" text-xs">{ele}</h6>
                </div>
              ))}
            </div>
          )}
          {data?.projects?.length > 0 && (
            <div className=" mt-3">
              <h6 className="mb-4 text-v5-neutral-200">
                {data.projects.length > 1 && data.projects.length} Work-like
                Professional Projects
              </h6>
              <div className="text-sm">
                <ul className="m-0 pl-6">
                  {data?.projects?.map((ele, idx) => (
                    <li key={idx} className="mb-2">
                      <h6 className=" text-sm">
                        {ele.title ? ele.title + ' -' : ''}{' '}
                        <span className="font-normal">{ele.description}</span>
                      </h6>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {data?.miniProjects?.length > 0 && (
            <div className=" mt-3">
              <h6 className="mb-2 text-v5-neutral-200">
                {data.miniProjects.length > 1 && data.miniProjects.length}{' '}
                Mini-projects
              </h6>
              <div className="text-sm">
                <ul className="m-0 pl-6">
                  {data?.miniProjects?.map((ele, idx) => (
                    <li key={idx} className="mb-2">
                      <h6 className=" text-sm">
                        {ele.title ? ele.title + ' -' : ''}{' '}
                        <span className="font-normal">{ele.description}</span>
                      </h6>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {data?.workshops?.length > 0 && (
            <div className=" mt-3">
              <h6 className="mb-4 text-v5-neutral-200">
                {data.workshops.length > 1 && data.workshops.length} Hands-on
                Workshop
              </h6>
              <div className="text-sm">
                <ul className="m-0 pl-6">
                  {data?.workshops?.map((ele, idx) => (
                    <li key={idx} className="mb-2">
                      <p>
                        <RawHTML>{ele}</RawHTML>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        {cards.length > 0 && (
          <>
            <div className="md:pl-[54px]">
              <hr className="my-3 border-b border-crio-green-400 opacity-20" />
              <h6 className="font-manrope text-white">{data.cardTitle}</h6>

              <div className="my-4 flex flex-wrap items-stretch gap-5 ">
                {cards.map(
                  (ele, idx) => ele?.data !== null && <Card data={ele}></Card>,
                )}
              </div>
            </div>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

const FAQCustomAccordion = ({
  data,
  expanded,
  handleChange,
  classes,
  image,
}) => {
  return (
    <Accordion
      disableGutters
      expanded={expanded}
      elevation={0}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit: true }}
      className={`rounded-[10px] bg-v5-green-700`}
      // {...(gradient && { style: { border: '1px solid #07634C' } })}
    >
      <AccordionSummary
        className={`w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6`}
        expandIcon={
          <div className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300">
            <FontAwesomeIcon
              icon={['fas', 'chevron-right']}
              className={`w-[10px] rotate-90 transition-all`}
            ></FontAwesomeIcon>
          </div>
        }
        classes={{ content: classes.content }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div>
          <div className="flex items-center gap-x-4">
            <div className="w-[40px]  min-w-[40px]">
              <GatsbyImage
                placeholder="blurred"
                loading="lazy"
                image={image}
                alt={'Curriculum'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <div>
              <h6 className={`text-base transition-all sm:w-full`}>
                <RawHTML>{data?.question}</RawHTML>
              </h6>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={`rounded-b-[30px] bg-v5-green-700 pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6`}
      >
        <div className="md:pl-[20px]">
          <p>
            <RawHTML>{data?.answer}</RawHTML>
          </p>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const Card = ({ data }) => {
  const image = data.image ? getImage(data.image.childImageSharp) : null;
  const spanToWidth = data?.spanToWidth;

  return (
    <div
      className={`${
        spanToWidth ? 'w-full' : 'md:max-w-[48%]'
      } rounded-[20px] bg-v5-green-600 p-4  text-v5-neutral-300  ${
        data?.title?.includes('iTunes') ? 'sm:order-last' : ''
      } `}
    >
      {image && (
        <div className="mb-4 w-[90px]">
          <GatsbyImage
            placeholder="blurred"
            loading="lazy"
            image={image}
            alt={'Curriculum'}
            style={{ maxHeight: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      )}
      <h6 className="mb-2">{data.title}</h6>
      <div className="text-sm">
        <div>
          {data?.data?.map((ele, idx) => (
            <div key={idx}>
              <div className="mb-2 flex items-start gap-x-2">
                {data.data.length > 1 && (
                  <div className="w-[14px] min-w-[14px]">
                    <StaticImage
                      width={14}
                      loading="lazy"
                      src="../../../images/v5/ProgramCards/tick.png"
                      placeholder="none"
                      alt="Tick"
                      className="mt-1"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                )}
                <div>
                  <div className="leading-relaxed">
                    <RawHTML>{ele}</RawHTML>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          {data?.skills?.length > 0 && (
            <div className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3">
              {data?.skills?.map((ele, idx) => (
                <div
                  key={idx}
                  className="rounded-[5px] border-2 border-v5-neutral-300 p-1 px-3"
                >
                  <h6 className=" text-xs">{ele}</h6>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FspCurriculumSection;
