import Button from '@components/Button/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import * as styles from './ProjectCard.module.scss';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import downloadableProjects from '../../../utils/downloadable-projects';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_PROJECTS_PAGE_DOWNLOAD,
  LEAD_GEN_PROJECTS_PAGE_NOTIFY,
} from '@src/constants/LeadGenerationConstants/index';
import { useCustomToast } from '@components/extra/useCustomToast';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';

export default function ProjectCard(props) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const triggerSuccessToast = useCustomToast(
    'success',
    <div>
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-sm">
        The complete project plan is on its way to your inbox.
        <br />
        In case you don't see it within 5-10 mins, please check your other email
        folders (like promotions).
      </p>
    </div>,
  );

  const triggerFailureToast = useCustomToast(
    'error',
    <div>
      <h2 className="font-base font-rubik text-lg">Oops!</h2>
      <p className="font-rubik text-sm">
        You can only download 3 projects in a month. Check back later!
      </p>
    </div>,
  );

  const triggerNotificationToast = useCustomToast(
    'success',
    <div>
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-sm">
        You’ll be notified once the download is available.
      </p>
    </div>,
  );

  useEffect(() => {
    calculateLimitExceeded();
  }, []);

  function getDifficulty(level) {
    let response = '';
    switch (level) {
      case 'ME_DIFFICULTY_TYPE_BEGINNER':
        response = 'Beginner';
        break;
      case 'ME_DIFFICULTY_TYPE_INTERMEDIATE':
        response = 'Intermediate';
        break;
      case 'ME_DIFFICULTY_TYPE_ADVANCED':
        response = 'Advanced';
        break;
      default:
        response = 'Beginner';
    }
    return response;
  }

  function diff_months(dt2, dt1) {
    var diff = (dt2 - dt1) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;
    return Math.abs(Math.round(diff));
  }

  const calculateLimitExceeded = () => {
    if (state.projectDownloadCount?.timestamp) {
      let months = diff_months(
        Date.now(),
        state.projectDownloadCount?.timestamp,
      );
      if (months > 0) {
        dispatch({
          type: 'RESET_PROJECT_DOWNLOAD_COUNT',
        });
      }
    }
    return state.projectDownloadCount?.count || 0;
  };

  const handleSubmit = (email, phone) => {
    GTM.setEmail(email);
    if (isDownloadable) {
      dispatch({
        type: 'SET_PROJECT_DOWNLOAD_COUNT',
      });
      const limit = state.projectDownloadCount?.count;
      if (limit >= 3) {
        triggerFailureToast();
        return;
      }
    }

    GTM.login({
      userId: email,
      email: email,
      phone: phone,
      whatsappNumber: phone,
    });

    GTM.track(gtmEvents.EMAIL_SUBMITTED, {
      type: 'PROJECT',
    });

    if (isDownloadable) {
      GTM.track(gtmEvents.PROJECT_DOWNLOAD_APPLIED_SUCCESSFULLY, {
        id: props.project.meId,
      });
      GTM.leadSquaredEvent(ELeadSquaredActivityCode.PROJECT_DOWNLOADED, email);
      triggerSuccessToast();
    } else {
      GTM.track(gtmEvents.PROJECT_NOTIFY_APPLIED_SUCCESSFULLY, {
        id: props.project.meId,
      });
      triggerNotificationToast();
    }
  };

  const isDownloadable = downloadableProjects.includes(props.project.meId);
  return (
    <>
      <div className="items-between flex h-full justify-center overflow-hidden  text-sm">
        <div
          className={`relative flex flex-col justify-between ${styles.project}`}
        >
          <div>
            <p className={`bodyText2 ${styles.categoryName}`}>
              {props.project.category}
            </p>
            <h6 className="mb-2 font-semibold">{props.project.meTitle}</h6>
            <p className="mb-4 font-light">{props.project.meDescription}</p>
            <div className="row">
              {props.project.meAuthorDetails.fullName && (
                <div className="col-5 col-lg-4">
                  <div className="mb-2">
                    <p className={`bodyText2 m-0 ${styles.categoryName}`}>
                      Author
                    </p>{' '}
                    <div className=" flex items-center">
                      <div className="mr-1 flex flex-wrap font-light">
                        {props.project.meAuthorDetails.fullName}
                      </div>
                      {props.project.meAuthorDetails.linkedInLink && (
                        <a
                          href={props.project.meAuthorDetails.linkedInLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div>
                            {' '}
                            <FontAwesomeIcon
                              className="mr-1  "
                              icon={['fab', 'linkedin']}
                              style={{ color: '#0e76a8' }}
                              size="1x"
                            />
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {props.project.meCollaboratorsDetails.length > 0 && (
                <div className="col-7 col-lg-8">
                  <div className="mb-3">
                    <p className={`bodyText2 m-0 ${styles.categoryName}`}>
                      Collaborator(s)
                    </p>{' '}
                    <div
                      className="flex flex-wrap font-light"
                      style={{ whiteSpace: 'pre' }}
                    >
                      {props.project.meCollaboratorsDetails.map(
                        (collaborator, idx) => (
                          <div key={idx} className="flex flex-wrap font-light">
                            {collaborator.fullName ? collaborator.fullName : ''}
                            {collaborator.linkedInLink && (
                              <a
                                href={collaborator.linkedInLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="">
                                  {' '}
                                  <FontAwesomeIcon
                                    className="mr-1"
                                    icon={['fab', 'linkedin']}
                                    style={{ color: '#0e76a8' }}
                                    size="1x"
                                  />
                                </div>
                              </a>
                            )}
                            {idx !==
                            props.project.meCollaboratorsDetails.length - 1
                              ? ', '
                              : ''}
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mb-3">
              <p className={`bodyText2 m-0 ${styles.categoryName}`}>
                Prerequisite(s)
              </p>{' '}
              <div className="flex flex-wrap font-light">
                {props.project.mePreRequisite}
              </div>
            </div>
            <div
              className={`subtitle2 mb-3  font-light ${styles.infoBanner} flex items-center `}
            >
              <div className=" mr-3 mt-1 mb-1 flex items-center">
                <FontAwesomeIcon className="mr-1  " icon={['far', 'clock']} />{' '}
                <div>{props.project.meDuration}</div>
              </div>{' '}
              <div className=" mr-3 mt-1 mb-1 flex items-center">
                <FontAwesomeIcon className="mr-1" icon={['far', 'signal']} />{' '}
                <div>
                  Difficulty: {getDifficulty(props.project.meDifficulty)}
                </div>
              </div>
            </div>
            {props.project.meSkills.length > 1 && (
              <>
                <p className={`bodyText2 ${styles.categoryName}`}>
                  Skills to be Learned
                </p>{' '}
                <div className="flex flex-wrap font-light">
                  {props.project.meSkills.split(',').map((skill, idx) => (
                    <div key={idx} className={styles.skillPill}>
                      {skill.trim()}
                    </div>
                  ))}
                </div>
              </>
            )}{' '}
          </div>
          <div className="flex flex-row justify-start ">
            <div className="mr-4">
              <a
                href={'/projects/' + props.project.meSlugUrl}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  GTM.leadSquaredEvent(
                    ELeadSquaredActivityCode.VIEW_PROJECT_CLICKED,
                    state?.email,
                    window?.location.pathname,
                  );
                }}
              >
                <Button
                  type="outlined"
                  className="mt-4 text-sm md:px-4"
                  color="#7BBA8C"
                  hovercolor="#fff"
                  style={{ height: '40px' }}
                >
                  View Project
                </Button>
              </a>
            </div>
            <div>
              {isDownloadable ? (
                <LeadGenerationButton
                  isOnePageForm
                  formHeading="Download Project"
                  pageOneButtonText="Download Now"
                  buttonLocation={LEAD_GEN_PROJECTS_PAGE_DOWNLOAD}
                  pageOneGradYear
                  pageOneWorkingProfessional
                  onSubmitPageOne={handleSubmit}
                  {...(state.email &&
                    state.phone && {
                      onClick: () => {
                        handleSubmit(state.email, state.phone);
                      },
                    })}
                  clickEvent={() => {
                    const limit = state.projectDownloadCount?.count;

                    if (!(limit > 2)) {
                      GTM.track(gtmEvents.PROJECT_DOWNLOAD_CLICKED, {
                        id: props.project.meId,
                      });
                    }
                  }}
                  buttonComponent={
                    <Button
                      background="#7BBA8C"
                      color="#fff"
                      hovercolor="#fff"
                      style={{ height: '40px' }}
                      className="shimmer-btn mt-4 text-sm md:px-4"
                    >
                      <FontAwesomeIcon
                        className="mr-2 cursor-pointer"
                        icon={['fas', 'download']}
                        style={{ color: '#fff' }}
                        size="1x"
                      />
                      Download Project
                    </Button>
                  }
                ></LeadGenerationButton>
              ) : (
                <LeadGenerationButton
                  isOnePageForm
                  formHeading="Notify When Download is available"
                  pageOneButtonText="Submit to notify"
                  buttonLocation={LEAD_GEN_PROJECTS_PAGE_NOTIFY}
                  pageOneGradYear
                  pageOneWorkingProfessional
                  onSubmitPageOne={handleSubmit}
                  {...(state.email &&
                    state.phone && {
                      onClick: () => {
                        handleSubmit(state.email, state.phone);
                      },
                    })}
                  clickEvent={() => {
                    GTM.track(gtmEvents.PROJECT_NOTIFY_CLICKED, {
                      id: props.project.meId,
                    });
                  }}
                  buttonComponent={
                    <Button
                      background="#F7C47E"
                      color="#222222"
                      hovercolor="#222222"
                      style={{ height: '40px' }}
                      className="mt-4 text-sm md:px-4"
                    >
                      <FontAwesomeIcon
                        className="mr-2 cursor-pointer"
                        icon={['fas', 'bell']}
                        style={{ color: '#222222' }}
                        size="1x"
                      />
                      Notify when download is available
                    </Button>
                  }
                ></LeadGenerationButton>
              )}
            </div>
          </div>
        </div>{' '}
      </div>
    </>
  );
}
