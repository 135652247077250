import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const qaCurriculumData = {
  curriculum: [
    {
      title: 'Introductory Session',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/One.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: 'FREE_TRIAL',
      data: [
        'Learn about Web-based Automation for websites like Redbus, Amazon, etc. and learn how to fetch the page, extract element data and use it to automate tasks in the process.',
        'Understand how platforms like Leetcode, Hackerrank test your code submissions. This will help you learn test data identification, manual testing and automated testing. ',
      ],
      skills: ['Manual testing', 'Automated testing', 'Web-based automation'],
      projects: null,
      anchor: 'introductory-session',
    },
    {
      title: 'Programming Essentials',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Two.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '8 weeks',
      tag: null,
      data: [
        'Strengthen your foundational programming skills through a series of hands-on activities and curated problem statements.',
      ],
      skills: [
        'Java',
        'OOPS',
        'Collections',
        'Exception Handling',
        'Debugging',
        'JUnit',
      ],
      projects: null,
      anchor: 'programming-essentials',
    },
    {
      title: 'Developer Essentials',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Three.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '2 weeks',
      tag: null,
      data: [
        'Get introduced to key skills needed to be an effective SDET through mini-projects and hands-on developer activities.',
      ],
      skills: [
        'Agile',
        'SQL',
        'Linux',
        'Git',
        'HTML Basics',
        'Gradle',
        'CI/CD',
        'Jenkins',
        'Bugzilla',
        'TestRail',
      ],
      projects: null,
      anchor: 'developer-essentials',
    },
    {
      title: 'Testing Essentials',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Four.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '2 weeks',
      tag: null,
      data: [
        'Through carefully designed workshops and activities, master testing fundamentals like Test Strategy, Test Data Generation, Test Case Design, Defect Life Cycle, Software Testing Levels, Software Testing Types, Estimation, Metrics, and Testability.',
      ],
      skills: null,
      projects: null,
      anchor: 'testing-essentials',
    },
    {
      title: 'Hands-On SDET: Real Web Apps',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Five.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '16 weeks',
      tag: null,
      data: [
        'Through a carefully designed series of hands-on workshops and mini-projects, learn fundamental testing concepts that are essential to become a strong SDET.',
      ],
      skills: [
        'Selenium',
        'Web Driver',
        'XPath',
        'Apache POI',
        'Page Object Model',
        'Framework',
        'Grid',
        'TestNG',
        'Cucumber',
      ],
      projects: [
        {
          title: 'QKart',
          description:
            'Create Automated Test Cases for a Flipkart like web-based application with guidance from experienced mentors',
        },
        {
          title: 'QTrip',
          description:
            'Architect a Selenium Framework using concepts like Page Object Model, Test NG, Extent Reports etc. and automate test cases for an AirBnB like web-based application using this framework',
        },
      ],
      anchor: 'hands-on-sdet-real-web-apps',
    },
    {
      title: 'Data Structures and Algorithms',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Six.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '8 weeks',
      tag: null,
      data: [
        'Master essential Data Structures from an interview perspective and understand their applications in the real world. Learn the Crio methodology to crack any programming problem.',
      ],
      skills: [
        'Time space complexity',
        'Arrays',
        'Linked List',
        'Stack/Queue',
        'Trees',
        'Hash',
        'Sort',
        'Search',
      ],
      projects: null,
      anchor: 'data-structures-and-algorithms',
    },
    {
      title: 'Specialization in Advanced Testing Concepts',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Seven.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '8 weeks',
      tag: null,
      data: [
        'Step up to the next level by mastering advanced testing concepts like API Testing and Performance Testing for APIs and Databases by working on additional professional work-like projects.',
      ],
      skills: [
        'API Testing',
        'Performance Testing',
        'JMeter',
        'RESTful API',
        'REST Assured',
        'Postman',
      ],
      projects: [
        {
          title: 'QTrip',
          description:
            'Create Automated Test Cases for an AirBnB like web-based application’s RESTful APIs and execute these along with the UI tests',
        },
      ],
      anchor: 'specialization-in-advanced-testing-concepts',
    },
  ],
  advancedCurriculum: [
    {
      title:
        'Master SDET skills with a live distributed backend, like Flipkart, on the cloud',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/One.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '7 weeks',
      data: [
        '<div><p>Create and execute integration tests on LIVE Flipkart-like distributed backend systems deployed on AWS.</p><ul><li>Implement API testing to test individual microservices on the backend.</li><li>Implement DB testing to test correctness of SQL tables for the common user journeys.</li><li>Write e2e integrations test for data flows involving Kafka queues, REST APIs and DB across microservices.</li><li>Benchmark API performance for microservices using JMeter (Performance testing).</li></ul></div>',
      ],
      skills: [
        'API',
        'API Testing',
        'DB Testing',
        'Performance Testing',
        'Microservices Testing',
        'Integration Testing',
        'Kafka',
        'JMeter',
      ],
      anchor: 'master-sdet-skills-with-live-distributed-backend',
    },
    {
      title: 'Test Strategy & Design for various system architectures',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Two.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '2 weeks',
      data: [
        '<div><p>Learn the nuances of different system architectures and how to effectively test them:</p><ul><li>Big Data systems</li><li>Event driven architecture</li><li>Microservices</li><li>N-tier application</li></ul></div>',
      ],
      skills: ['System Architecture', 'Test Strategy', 'Test Plan'],
      anchor: 'test-strategy-and-design-for-various-system-architectures',
    },
    {
      title:
        'Cloud/DevOps Foundations and SDLC Best Practices for Automation/SDET',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Three.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '3 weeks',
      data: [
        'Learn important concepts required to become a competent new-age automation/SDET engineer. Get familiar with cloud concepts like virtualization, containerization and then get introduced to cloud services around compute, storage and networking.',
      ],
      skills: [
        'Cloud concepts',
        'Cloud services',
        'CI/CD',
        'Virtualization',
        'Docker',
        'Deployment',
        'Scripting',
        'Advanced Git',
        'Test Bed Creation',
        'Swagger',
        'Postman',
        'BDD',
        'TDD',
        'Agile',
      ],
      anchor:
        'cloud-devops-foundations-and-sdlc-best-practices-for-automation-sdet',
    },
  ],
};
