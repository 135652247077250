import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EScholarship } from '@src/constants/sale/index';
import useAccelerate from '@src/hooks/useAccelerate';
import { cn } from '@/lib/utils';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';

const getPriceCard = (sale, price, duration, emiDuration, program, dark) => {
  switch (sale) {
    case 'true':
      return (
        <div className="">
          <div className="flex min-h-[190px] flex-col items-start justify-center whitespace-nowrap rounded-[30px] bg-sale-orange-50 p-8 pb-20 text-black sm:w-7/12 md:w-full">
            {' '}
            <div className="rounded-[5px] bg-sale-blue-50 p-1 px-2 text-sm font-semibold uppercase text-v5-neutral-500">
              <p>For {duration}</p>
            </div>
            <div className="flex items-center gap-x-1">
              <h1 className="">
                <FontAwesomeIcon
                  icon={['fas', 'indian-rupee-sign']}
                  size="sm"
                  className="mr-2 text-sale-neutral-300"
                ></FontAwesomeIcon>
                {price}
              </h1>
              <p className="mt-3 text-v5-green-300 sm:ml-2 md:text-sale-neutral-300">
                /month
              </p>
            </div>
            <h6 className="text-sale-neutral-500 ">Before Scholarship</h6>
          </div>
          <div className="relative flex h-[150px] -translate-y-10 items-start justify-center rounded-[20px] bg-sale-blue-300 md:h-[200px]">
            <div className="mx-2 mt-6 text-center font-manrope text-white">
              <h2 className="font-rubik text-base font-normal text-sale-orange-100 md:text-lg">
                <span className="font-bold italic">#AddProjectsToResume</span>{' '}
                and crack interviews.
              </h2>
              <p className="mt-2 text-sm font-black">
                Get a Rs.{EScholarship[program]} scholarship.
                <br />
                <span className="text-xs font-normal">Limited Time Offer.</span>
              </p>
            </div>
            <div className="absolute -bottom-[39px] left-1/2 h-[78px] w-[78px] -translate-x-1/2">
              <StaticImage
                loading="lazy"
                src="../../../images/v5/Sale/Card-Motif.png"
                placeholder="blurred"
                alt="Sale Motif"
                width={180}
              />
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div
          className={cn(
            'flex  min-h-[190px] w-full flex-col items-start justify-center whitespace-nowrap rounded-[30px]  p-8 text-black sm:min-h-full sm:w-[53%]',
            `${dark ? 'bg-v5-green-100' : 'bg-v5-neutral-100'}`,
          )}
        >
          {' '}
          <div className="rounded-[5px] bg-v5-yellow-300 p-1 px-2 text-sm font-semibold uppercase text-v5-neutral-500">
            <p>{duration}</p>
          </div>
          <div className="flex items-center gap-x-1">
            <h1>
              <FontAwesomeIcon
                icon={['fas', 'indian-rupee-sign']}
                size="sm"
                className="mr-2 text-v5-green-300"
              ></FontAwesomeIcon>
              {price}
            </h1>
            <p className="mt-3 text-v5-green-300 sm:ml-4">/month</p>
          </div>
          <p className="-mt-2 text-sm text-v5-green-300">
            (For {emiDuration} months)
          </p>
          <h6 className="mt-3 text-v5-neutral-500">Before Scholarship</h6>
        </div>
      );
  }
};

const FspPricingCardSection = React.memo(function FspPricingCardSection({
  price,
  duration,
  ...props
}) {
  const { isAcceleratePage } = useAccelerate();
  const { isDataSciencePage } = useDataScienceProgram();

  const isVariantTwo = isAcceleratePage || isDataSciencePage;

  const emiDuration = isAcceleratePage ? '24' : '36';

  return (
    <SectionLayout
      heading={
        props.heading ? props.heading : <>Program Fee and Scholarships</>
      }
      {...props}
    >
      <div className="container flex justify-center">
        <div
          className={cn(
            'w-max rounded-[30px] p-8 text-v5-neutral-200 sm:px-12 ',
            `${props.dark ? 'bg-v5-green-500' : 'bg-v5-green-100'}`,
          )}
        >
          <div className="flex flex-wrap items-stretch  gap-x-8 gap-y-8 sm:flex-nowrap ">
            {getPriceCard(
              props.sale,
              price,
              duration,
              emiDuration,
              props.program,
              props.dark,
            )}
            <div className="h-full w-full self-center">
              <PricingCardLabel
                title={'A Free Trial Session'}
                description={'No Fee Required'}
                image={
                  <StaticImage
                    width={40}
                    loading="lazy"
                    src="../../../images/v5/ProgramCards/tick.png"
                    placeholder="none"
                    alt="Tick"
                    className="mt-1"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                }
                variant={isVariantTwo ? 'label' : null}
                {...props}
              />
              <PricingCardLabel
                title={'Assured Scholarships'}
                description={'After Free Trial'}
                image={
                  <StaticImage
                    width={40}
                    loading="lazy"
                    src="../../../images/v5/ProgramCards/tick.png"
                    placeholder="none"
                    alt="Tick"
                    className="mt-1"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                }
                variant={isVariantTwo ? 'label' : null}
                className={'mt-4'}
                {...props}
              />

              {!isVariantTwo ? (
                <div className="text-sm leading-relaxed">
                  <p
                    className={cn(
                      `${props.dark ? '' : 'text-v5-neutral-600'}`,
                      'mb-2',
                    )}
                  >
                    Pay the success fee only if you land a job{' '}
                    <br className="hidden sm:block"></br>
                    within 6 months of <b>placement eligibility date</b>
                  </p>
                  <p className={cn(`${props.dark ? '' : 'text-v5-green-700'}`)}>
                    Success fee is Rs. 19,999 if CTC is less than 20 LPA.
                    <br></br>
                    Success fee is Rs. 39,999 if CTC is greater than 20 LPA.
                    <br></br>12 month no-cost EMI and 18, 24 &amp; 36 month
                    low-cost EMI available.
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
});

const PricingCardLabel = ({
  title,
  description,
  image,
  variant,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(
        'mb-4 flex items-start gap-x-2',
        `${
          variant === 'label'
            ? `mb-0 rounded-[30px]  py-10  px-12 ${
                props.dark ? 'bg-[#013437]' : 'bg-v5-neutral-100'
              }`
            : ''
        }`,
        className,
      )}
    >
      <div className="w-[20px] ">{image}</div>
      <div>
        <h6
          className={cn(
            `${props.dark ? 'text-v5-neutral-100' : 'text-v5-neutral-600'}`,
          )}
        >
          {title}
        </h6>
        <p
          className={`font-medium ${
            props.dark ? 'text-v5-neutral-400' : 'text-v5-green-700'
          }`}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default FspPricingCardSection;
