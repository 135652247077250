// SpecializationOptions.js
import useResizer from '@components/extra/useResizer';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import React from 'react';

const SpecializationOptions = ({
  specializationType,
  selectedSpecialization,
  setSelectedSpecialization,
  light,
  hideOptions,
}) => {
  const isMobile = useResizer();
  const getOptionClassName = (index) => {
    const baseColor = 'bg-[#7A8B87]';
    const selectedColor = index === 0 ? 'bg-[#2DF8C5]' : 'bg-[#ABED4F]';
    return `rounded-[5px] px-[10px] py-1 md:text-xs text-[6px] font-semibold uppercase text-black ${
      selectedSpecialization === index ? selectedColor : baseColor
    }`;
  };

  const handleSpecializationTabClick = (idx, title) => {
    setSelectedSpecialization(idx);

    GTM.track(gtmEvents.SPECIALIZATION_TYPE_SWITCHED, {
      specialization: title,
    });
  };

  return (
    specializationType && (
      <>
        {isMobile && (
          <div className="text-[12px] text-[#FFFFFF66]">
            {specializationType[0]?.title?.split(' ')[1]} in:
          </div>
        )}
        <div className="mb-4 flex justify-between gap-2  border-b-2 border-[#0f2922] md:justify-start md:gap-10">
          {specializationType.map((ele, idx) => (
            <div
              key={idx}
              className={`relative flex w-full items-center justify-center gap-1 py-3 md:w-auto md:cursor-pointer md:flex-col md:items-start md:justify-start md:gap-1 md:px-2`}
              onClick={() => handleSpecializationTabClick(idx, ele?.title)}
            >
              {!hideOptions && (
                <div className={`${getOptionClassName(idx)} `}>
                  Option {idx + 1}
                </div>
              )}
              <div
                className={`text-[14px] font-[700] leading-[27px] md:text-[16px] ${
                  idx === selectedSpecialization
                    ? light
                      ? 'text-[#009378]'
                      : 'text-white'
                    : light
                    ? 'text-v5-neutral-600'
                    : 'text-[#7A8B87]'
                }`}
              >
                {isMobile ? ele?.title.split(' ')[0] : ele?.title}
              </div>
              {selectedSpecialization === idx && (
                <div className="absolute bottom-[-2px] left-[-1px] z-10 h-[6px] w-full rounded-tr-[8px] rounded-tl-[8px] bg-[#009378]"></div>
              )}
            </div>
          ))}
        </div>
      </>
    )
  );
};

export default SpecializationOptions;
