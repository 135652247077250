import React, { useContext } from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import GreenBanner from '../GreenBanner/index';
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from '@src/context/GlobalContextProvider';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { useCustomToast } from '@components/extra/useCustomToast';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config.js';
import { useLocation } from '@reach/router';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_DOWNLOAD_CURRICULUM } from '@src/constants/LeadGenerationConstants/index';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { EButtonType } from '@src/constants/data/programs/index';
import DreamJobCurriculum from './DreamJobCurriculum/index';
import SuperDreamJobCurriculum from './SuperDreamJobCurriculum/index';
import fsdCurriculumJsonData from '@src/PageData/fsdCurriculum';
import useAccelerate from '@src/hooks/useAccelerate';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';

const fullConfig = resolveConfig(tailwindConfig);

const FspCurriculumSectionV3 = React.memo(function FspCurriculumSectionV3({
  type,
  dreamCompanies,
  superDreamCompanies,
  isTypeQA,
  isTypeFellowShipPlus,
  advancedCurriculumHeading,
  hideHeader,
  curriculumV2,
  ...props
}) {
  const location = useLocation();
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const downloadCurriculumType = isTypeFellowShipPlus
    ? 'FELLOWSHIP_PLUS'
    : isTypeQA
    ? 'QA_AUTOMATION_TESTING'
    : 'FELLOWSHIP';

  const { isAcceleratePage } = useAccelerate();
  const { isDataSciencePage } = useDataScienceProgram();

  const hideAdvancedCurriculumSection = isAcceleratePage || isDataSciencePage;

  const triggerSuccessToast = useCustomToast(
    'success',
    <div className="">
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-xs">
        You will receive the curiculum in your email shortly.
        <br /> (In case you have your adblocker turned on, please write to us at{' '}
        <a href="mailto:programs@criodo.com" className="font-bold">
          programs@criodo.com
        </a>{' '}
        to receive the curriculum)
      </p>
    </div>,
  );

  const dreamJobRange = props.dreamJobRange || '6 To 18 LPA';
  const superDreamJobRange = props.superDreamJobRange || '15 To 40 LPA';

  const triggerCurriculumWebengageFlow = (email, phone, type) => {
    GTM.login({
      userId: email,
      email: email,
      phone: phone,
      whatsappNumber: phone,
    });
    GTM.setEmail(email);

    if (phone) {
      GTM.track(gtmEvents.PHONE_SUBMITTED, {
        phone: phone,
        type: 'DOWNLOAD_CURRICULUM_STRIP',
      });
    }

    window.webengage &&
      window.webengage.user.setAttribute('we_whatsapp_opt_in', true);

    //GTM Event for Email Capture
    if (!state.isEmailEventSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: downloadCurriculumType,
        location: 'DOWNLOAD_CURRICULUM_STRIP',
      });
      dispatch({
        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
        payload: true,
      });
    }

    GTM.track(gtmEvents.DOWNLOAD_CURRICULUM_APPLIED_SUCCESSFULLY, {
      type: downloadCurriculumType,
      location: 'STRIP',
    });

    if (type == EButtonType.DOWNLOAD_CURRICULUM_BUTTON) {
      GTM.leadSquaredEvent(
        ELeadSquaredActivityCode.SD_PAGE_DOWNLOAD_CURRICULUM_CLICKED,
        email,
      );
    } else {
      GTM.leadSquaredEvent(
        ELeadSquaredActivityCode.SD_PAGE_DOWNLOAD_CURRICULUM_SUBMITTED,
        email,
      );
    }

    triggerSuccessToast();
  };

  //FSD REVAMPED CURRICULUM SECTION:
  const { basicCurriculumFSD, advancedCurriculumFSD } =
    curriculumV2 || fsdCurriculumJsonData;

  return (
    <SectionLayout
      heading={
        hideHeader
          ? ''
          : 'An All-round Effective Way to Supercharge your Tech Career'
      }
      description={
        hideHeader ? (
          <></>
        ) : (
          <>
            <strong className="font-bold text-black">
              Hands-on, work experience-based learning
            </strong>{' '}
            is at the heart of everything you do at Crio. Build software
            projects like the top 1% Tech Professionals and{' '}
            <strong className="font-bold text-black">
              learn all the skills
            </strong>{' '}
            you need to{' '}
            <strong className="font-bold text-black">land the best</strong>{' '}
            Fullstack/Backend Developers or SDET Jobs.
          </>
        )
      }
      {...props}
    >
      <div className="container ">
        {hideHeader ? (
          <></>
        ) : (
          <div className="mb-16 flex justify-center">
            <GreenBanner
              gradient={props.gradient}
              quote={props.quote}
              postContent={
                <>
                  <LeadGenerationButton
                    type="Download"
                    text="Download Curriculum"
                    isOnePageForm
                    formHeading="Program Curriculum"
                    pageOneButtonText="Download Now"
                    buttonLocation={LEAD_GEN_DOWNLOAD_CURRICULUM}
                    onSubmitPageOne={triggerCurriculumWebengageFlow}
                    clickEvent={() => {
                      GTM.track(gtmEvents.DOWNLOAD_CURRICULUM_CLICKED, {
                        type: type,
                        location: 'STRIP',
                        slug: props.slug,
                      });
                    }}
                    {...(state.email &&
                      state.phone && {
                        onClick: () => {
                          triggerCurriculumWebengageFlow(
                            state.email,
                            state.phone,
                            EButtonType.DOWNLOAD_CURRICULUM_BUTTON,
                          );
                        },
                      })}
                  ></LeadGenerationButton>
                </>
              }
            ></GreenBanner>
          </div>
        )}

        {!props.hideCurriculumSection && (
          <div className="mb-20 rounded-[50px] bg-v5-green-500 p-4  text-white  sm:p-6 ">
            <DreamJobCurriculum
              curriculum={basicCurriculumFSD}
              type={type}
              dreamJobRange={dreamJobRange}
            />
            {!hideAdvancedCurriculumSection ? (
              <SuperDreamJobCurriculum
                curriculum={advancedCurriculumFSD}
                type={type}
                superDreamJobRange={superDreamJobRange}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </SectionLayout>
  );
});

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `none`,

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: '100%',
    height: props.isExpanded ? '100%' : 'auto',
    overflow: 'hidden',
    background: fullConfig.theme.colors['v5']['green']['700'],
    // border: props.isExpanded ? '1px solid white !important' : '',
  }),
  content: {
    margin: '8px 0px',
  },
}));

export default FspCurriculumSectionV3;
