/** @type {import('tailwindcss').Config} */

const defaultTheme = require('tailwindcss/defaultTheme');
module.exports = {
  important: true,
  content: [
    './src/**/*.{js,jsx,ts,tsx}',
    './src/**/**/*.{js,jsx,ts,tsx}',
    './@/**/*.{js,jsx,ts,tsx}',
  ],
  darkMode: false, // or 'media' or 'class'
  prefix: '',
  theme: {
    screens: {
      smallheight: { raw: '(max-height: 650px)' },
      tablet: { raw: '(min-height: 1000px)' },
      xxs: '411px',
      xxl: '1800px',
      ...defaultTheme.screens,
    },
    fontFamily: {
      'nunito-sans': ['Nunito Sans, sans-serif'],
      lato: ['Lato, sans-serif'],
      dosis: ['Dosis, sans-serif'],
      'uni-neue': ['Uni Neue'],
      calibri: ['Calibri'],
      rubik: ['Rubik, sans-serif'],
      manrope: ['Manrope, sans-serif'],
      inter: ['Inter', 'sans-serif'],
      dmSans: ['DM Sans', 'sans-serif'],
    },
    container: {
      center: true,
      padding: '2rem',
      screens: {
        '2xl': '1400px',
      },
    },
    extend: {
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
      },
      colors: {
        'phonepe-primary-pink': {
          50: '#F4EAFF',
          100: '#E4ACF3',
        },
        'phonepe-primary-purple': {
          100: '#424666',
          150: '#9181D7',
          200: '#9C53D6',
          400: '#6923B5',
          500: '#5F259E',
          600: '#411283',
          700: '#2F085A',
          800: '#190051',
        },
        'phonepe-accent': {
          300: '#00DDFF',
        },

        'crio-green': {
          25: '#E1FFD9',
          50: '#B2BF83',
          100: '#A4BE85',
          150: '#88DA8B',
          200: '#7BBA8C',
          300: '#45B696',
          400: '#0EB29F',
          500: '#0C9286',
          600: '#09736C',
          700: '#075353',
          800: '#064446',
          900: '#05343A',
          950: '#1E4003',
          1000: '#023538',
          1050: '#0D6055',
        },
        'crio-green-two': {
          10: '#31DB7D',
          50: '#7EEC9E',
          100: '#21B79C',
          200: '#018D78',
          300: '#00363B',
          400: '#002E31',
          500: '#001B19',
          600: '#20444E',
        },
        'crio-neutral-two': {
          10: '#F9F8F7',
          20: '#C3C0BB',
          30: '#B6B1AA',
          40: '#8F8B84',
          100: '#333333',
        },
        'crio-neutral': {
          50: '#fff',
          75: '#F9F8F7',
          100: '#F3F1EE',
          200: '#E3E1D5',
          250: '#DDD6CC',
          300: '#C9C1B6',
          500: '#77807E',
          600: '#8F8B84',
          700: '#626260',
          800: '#2F363D',
          900: '#2F2F2F',
        },
        'crio-yellow': {
          500: '#FFC772',
          700: '#FF980E',
        },
        'ibd-primary': {
          50: '#f4216d',
          100: '#FF216D',
        },
        'ibd-blue': {
          25: '#EEF1F3',
          50: '#E8E9FA',
          100: '#0CD9C7',
          200: '#48d9c6',
          300: '#24288A',
          400: '#0D153D',
        },
        'ibd-purple': {
          50: '#CCCDF4',
          100: '#B5B7F1',
          200: '#7E81DC',
        },

        primary: { 100: '#FFE500', 200: '#FFD500' },
        v5: {
          red: {
            100: '#FF0000',
            200: '#F3736A',
            300: '#FFF6F6',
            500: '#4D3230',
          },
          green: {
            100: '#2DF8C5',
            200: '#02BF91',
            300: '#009378',
            400: '#07634C',
            500: '#004246',
            600: '#003B3F',
            700: '#013437',
            800: '#0093781A',
            900: '#00937833',
            1000: '#2DF8C533',
          },
          lime: {
            100: '#F2F62E',
            200: '#D5E927',
            300: '#ABED4F',
            400: '#81F076',
            500: '#57F49E',
          },

          neutral: {
            100: '#FFFFFF',
            200: '#F2FBF7',
            250: '#e1e4e8',
            300: '#B9CFCA',
            400: '#7A8B87',
            500: '#525252',
            600: '#000000',
            700: '#00000033',
            800: '#E3E3E3',
            900: '#F4FFFC',
          },
          yellow: {
            100: '#FFE500',
            200: '#FFD500',
            300: '#FFEE99',
            400: '#FFF7CC',
          },
        },
        sale: {
          blue: {
            50: '#C3D5FA',
            300: '#004AD9',
          },
          orange: {
            50: '#FBEDD8',
            100: '#F7CC8D',
            200: '#FFCF88',
            300: '#EB7100',
            400: '#FF9900',
          },
          neutral: {
            300: '#AE877A',
            500: '#525252',
          },
          black: {
            300: '#001234',
          },
        },
        'copy-paste-sale': {
          green: {
            50: '#56E17C',
            100: '#00C435',
            500: '#074457',
            900: '#033448',
          },
          yellow: {
            100: '#FBEA56',
          },
          gray: {
            900: '#363636',
          },
          cream: {
            100: '#FCFADD',
          },
        },
        launch: {
          neutral: {
            100: '#ffffff',
            200: '#EDEDED',
            300: '#F0F1EA',
            400: '#3C3C3C',
            500: '#000000',
          },
          blue: {
            100: '#C9D8FF',
            200: '#5196FF',
            300: '#277AF7',
            400: '#196AE3',
            500: '#1F55DE',
            600: '#184CD3',
            700: '#1345C6',
            800: '#1040BB',
            900: '#082967',
          },
          green: {
            100: '#00FFA3',
          },
          blueFSP: {
            100: '#9DCCF7',
            200: '#44DDFF',
            300: '#0B4F80',
            400: '#083F67',
            500: '#053659',
          },
        },
        'lister-purple': {
          100: '#FFFFFF',
          150: '#F9F9FF',
          200: '#F1EFFF',
          250: '#DDD9F8',
          300: '#C5C2EA',
          400: '#B2ACEB',
          500: '#8479CB',
          600: '#4A3BA2',
          700: '#312A7C',
          800: '#2A2466',
        },
        'lister-other': {
          100: '#68657D',
          200: '#EF6E26',
          300: '#54A9FF',
        },
        'geek-trust': {
          neutral: {
            100: '#FFFFFF',
            200: '#ECEBFB',
            300: '#BDBDBD',
            450: '#93A7A2',
          },
          purple: {
            100: '#123978',
            200: '#2B2E48',
            300: '#172439',
          },
          green: {
            100: '#E8F4F1',
            200: '#04D2AF',
            300: '#2B9173',
          },
        },
      },
      backgroundColor: {
        'hall-of-fame-navbar': '#00100ef2',
      },
      boxShadow: {
        100: '0px 5px 10px 0px #0000001A',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [require('tailwindcss-animate'), require('tailwind-scrollbar-hide')],
};
